.cookie
	&__buttons
		display flex
		align-items center
	&__controls
		display flex
		align-items center
	&__question
		display flex
		align-items center
		justify-content space-between
	&-messege
		display flex
		justify-content space-between
		flex-direction column

		& span
			font-weight 600
		& p
			margin-bottom 16px
			color #C4C4C4
			line-height 1.2
		& h5
			margin-bottom 8px
		& a
			text-decoration underline
			&:hover
				text-decoration none
		&__wrapper
			position fixed
			z-index 10
			bottom 20px
			left 50%
			max-width 690px
			transform translateX(-50%)
			border-radius: 20px
			background #272621
			padding 32px 45px
			color #fff
			display flex
			align-items center
			visibility hidden
			opacity 0
			height 0
			overflow hidden
			transition all .6s
			&.show
				visibility visible
				opacity 1
				height auto
				transition all .6s
	&-list
		margin-bottom 16px
		&__wrapper
			display none
			margin-top 16px
			& p
				margin-bottom 0
		&__item
			padding 8px 0
			border-top 1px solid rgba(158, 158, 158, 0.25)
			&:last-of-type
				border-bottom 1px solid rgba(158, 158, 158, 0.25)
				margin-bottom 16px
			& i
				font-size 20px
				transition: all .5s
			&.open
				& i
					transform rotate(180deg)
	&__answer
		display none
		margin-top 8px
