/***
*
* Стилизация input type="checkbox"
*
***/
.checkbox
	text-align left
	align-self center
	label
		display inline-block
		width auto
	input[type="checkbox"]
		display: none
		height: 0
		visibility: hidden
		&:checked + label:before
			background $primary-yellow
		&:checked + label:after
			left 26px
	input[type="checkbox"] + label
		min-height 26px
		font-size 13px
		line-height 1
		font-weight unset
		text-align left
		padding-left 60px
		margin-bottom 0
		position: relative
		cursor pointer

		&:before
			content ''
			position absolute
			top 0
			left 0
			width 50px
			height 26px
			border-radius 13px
			background #f5f2f2
			transition .2s
			box-shadow inset 0 2px 3px rgba(146,144,144,.1)
		&:after
			content ''
			position absolute
			top 2px
			left 2px
			width 22px
			height 22px
			border-radius 10px
			background #fff
			box-shadow 0 2px 5px rgba(0,0,0,.3)
			transition .2s
	& a
		&:hover
			text-decoration: underline

	&--reverted
		input[type="checkbox"] + label
			padding-left 0
			padding-right 35px

			&:before
				right: 0
				left: auto

		input[type="checkbox"]:checked + label:after
			right: 6px
			left: auto
.checkbox-simple
	position: relative
	margin-right 24px
	& label
		background: rgba(158, 158, 158, 0.25);
		border: 1px solid rgba(153, 153, 153, 0.5);
		box-sizing: border-box;
		border-radius: 4px
		cursor: pointer;
		height: 20px;
		left: 0;
		position: absolute;
		top: 0;
		width: 20px;
		margin-bottom 0
	& label:after
		border: 2px solid #272621
		border-top: none;
		border-right: none;
		content: "";
		height: 6px;
		left: 3px;
		opacity: 0;
		position: absolute;
		top: 5px;
		transform: rotate(-45deg);
		width: 12px;
		transition: all .5s;
	& input[type="checkbox"]
		visibility: hidden;
	& input[type="checkbox"]:checked + label
		background-color: #FFD600
		border-color: #FFD600
	& input[type="checkbox"]:checked + label:after
		opacity: 1;