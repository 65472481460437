.fit-menu-total
	display flex
	flex-direction column
	align-items center
	text-align center
	border-radius 15px
	height 420px
	max-width 280px
	width 100%
	padding 40px 15px
	position relative
	background #ffffff
	background-image url(../img/backgrounds/bg_total.png)
	background-position bottom center
	background-repeat no-repeat
	background-size 100%
	box-shadow 0 1px 3px rgba(39,38,33,.1)
	& .fit-menu-item__params
		margin 25px 0
		& h6
			font-weight 700
			color #272621
