// -----------------------------------------------
// Component - Modal
// -----------------------------------------------
.mfp
	&-content
		border-radius 15px
		//background #ffffff
		.modals-wrapping
			display: flex
			flex-direction: column
			align-items: center
			//max-width 600px
			overflow: hidden
			box-sizing: border-box
			border-radius 15px
			//text-align center
			background #ffffff
			outline none
			& p
				& span
					color #FF3333
.order-wrapper
	display: flex
	flex-direction: column
	align-items: center
	//max-width 600px
	overflow: hidden
	box-sizing: border-box
	border-radius 15px
	//text-align center
	background #ffffff
	outline none
	& p
		& span
			color #FF3333

.info-modal-wrapper
	width 610px
	padding 60px 40px
	background-image url(../img/backgrounds/info-modal-bg.png), url(../img/backgrounds/info-modal.png)
	background-position: right bottom, center
	background-repeat: no-repeat, repeat
	background-color: #fff;
	border-radius: 30px;
	& h3
		font-size 38px
		text-align center
	& ul
		margin-bottom 20px
	& li
		font-weight 700
	& .modal__buttons
		max-width 100%
		& .button
			box-shadow 0 10px 15px rgba(0 0 0, 0.4)

.modal
	&__title
		text-align center
		text-transform uppercase
		margin-bottom 0
	&__list
		display: grid
		grid-template-columns: repeat(auto-fill, minmax(220px, auto))
		grid-gap: 25px
		li
			display: flex
			justify-content: center
			& a
				margin 5px 0
	&__buttons
		display flex
		justify-content space-evenly
		flex-wrap wrap
		width 100%
		max-width 360px
		& .button
			margin 10px

/*
====== Zoom effect ======
*/

.mfp-zoom-in
	.mfp-content
		opacity 0
		transition all 0.2s ease-in-out
		transform scale(0.4)
	&.mfp-bg
		opacity 0
		transition all 0.3s ease-out
	/* animate in */
	&.mfp-ready
		.mfp-content
			transition all 0.2s ease-in-out
			opacity 1
			transform scale(1)
		&.mfp-bg
			opacity 0.5
	/* animate out */
	&.mfp-removing
		.mfp-content
			transform scale(0.8)
			opacity 0
		&.mfp-bg
			opacity 0
/*
====== Move-from-top effect ======
*/
.mfp-move-from-top
	&.mfp-wrap
		top 70px
	.mfp-content
		vertical-align top
		max-width 100%
	/* start state */
	.mfp-content
		opacity 0
		transition: all 0.2s
		transform translateY(-100%)
	&.mfp-bg
		z-index 9
		opacity: 0
	/* animate in */
	&.mfp-ready
		.mfp-content
			transition all .4s
			opacity 1
			transform translateY(0)
	/* animate out */
	&.mfp-removing
		transition all 2s
		.mfp-content
			transform translateY(-100%)
			opacity 0
			transition all 2s
		&.mfp-bg
			opacity 0
/*
====== Effect for mobile menu ======
*/
.mfp-wrap.mobile-wrapper.mfp-ready
	z-index 9
	& .mfp-content
		width 100%
		height calc(100vh - 190px)
		overflow auto
		padding 25px
		text-align center
.mobile
	&-content
		border 1px solid white
	&-wrapper
		background-color #ffd600
		.mfp-container
			opacity: 0
			transition: all 0.3s
			transform: translateX(-50px)
		&.mfp-bg
			z-index 9
			background $primary-gray
			opacity 1
		/* animate in */
		&.mfp-ready
			.mfp-container
				opacity: 1
				transform: translateX(0)

		/* animate out */
		&.mfp-removing
			.mfp-container
				transform: translateX(50px)
				opacity: 0
			&.mfp-bg
				opacity: 0

.modal__wrapper
	padding 40px
.bag-description
	&__wrapper
		max-width 670px
		padding 50px 20px
		background #ffffff
		& h3
			font-weight: 700
			font-size: 14px
			line-height: 22px
			text-align center
		& p:last-child
			margin-bottom 0
	&__images
		display flex
		justify-content center
		align-items center
		flex-wrap wrap
		margin-bottom 20px
		& img
			margin 5px