.header-menu
	display: flex
	flex-wrap: nowrap
	&__item
		padding 10px
		position relative
		text-align center
		&-link
			transition: $t-color
			cursor: pointer
			display flex
			align-items center
			height 24px
			line-height 1
			&:hover,
			&.active
				color $primary-white
			&.gift
				z-index: 0
				&:before
					content ''
					display block
					width 24px
					height 24px
					background-image url('../img/elements/giftbox.svg')
					margin-right 10px
					margin-bottom 5px
				&:after
					content: ''
					width: 0
					background: #F03F3F
					position: absolute
					left: 50%
					z-index -1
					height 70px
					transform: translateX(-50%)
					transition: width .2s ease-out
				&:hover
					&:before
						background-image url('../img/elements/gift-white.svg')
					&:after
						width: 100%

.mfp-content .header-menu__item-link.gift:after
	content none

