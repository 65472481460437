@media all and (max-width: 640px)
	.section--subscribe .container
		padding-top 50px
		padding-bottom 0

	.section--subscribe__content
		max-width 100%
		text-align center
	.section--subscribe__img
		position unset
		width 100%
		margin-top -50px
	.form--subscribe
		justify-content center

	.weekly-nav
		& .day
			display none
		& .day-mobile
			display flex
	.fit-menu__description
		min-height 70px
		margin-bottom 40px
		padding 0 10px
	.fit-menu-tab .promotion__value
		left 50%
		transform translateX(-50%)
		right auto
		font-size 10px
		padding 3px 5px
		white-space nowrap
	.fit-menu-tab:not(:last-child)
		margin-right 0

	.youtube__post
		border-radius 15px
		overflow hidden
		max-width 260px
	.youtube__post.swiper-slide-active .youtube__post-link:before
		height 32px
		width 32px
	.slider-youtube__button-prev, .slider-youtube__button-next
		display none

	.sub-header.visible
		display none
	.order-monthly
		flex-direction column
		justify-content center
		height 370px
		border-radius 0
		text-align center
		background-image url(../img/backgrounds/bg-monthly-order-mobile.png)
		background-size cover
		background-repeat no-repeat
		background-position top center
		& h3
			margin 25px 0
		& .button
			width 220px


	// ----- Page layout
	.page-sidebar .container
		& .review__list, .blog__list
			grid-template-columns 1fr
	// ----- footer --------------------------------------------------------------
	.footer__logo
		margin-bottom 20px
	.footer__block-wrapper
		display flex
		flex-direction column
		justify-content center
	.footer__block
		align-items center
		text-align center
		&:not(:last-child)
			margin-bottom 50px
	.footer__text
		margin-bottom 0
	.footer__copyright
		flex-direction column-reverse
		text-align center
		& .footer__payments
			margin-bottom 50px
			justify-content: center
			& li
				margin 0 10px
	// ---- Modals
	//.test-menu-order__wrapper
	//	padding 30px

	.info-modal-wrapper
		min-width 310px
		max-width 100%
		padding 30px
		font-size 12px
		background-image url(../img/backgrounds/info-modal-bg-mob.png), url(../img/backgrounds/info-modal.png)
		background-size: 100%, contain
		& h3
			font-size 22px
		& ul
			width 50%
			& li
				margin-bottom 5px
		& .button--s5
			width 160px
			height 40px
			font-size 12px
			padding-top 3px
			margin 0


	.form--order-test-menu
		grid-template-columns 1fr
	.order__business
		& .form__input
			justify-content center
			& label
				display none
		& .ico-request
			flex-direction column
			& .form__input
				max-width 320px
				width 100%
				margin-bottom 30px
			& .button
				width 100%
				max-width 100%
	.certificate__header
		padding 20px
		flex-direction column-reverse
	.certificate-img, .blog-item-banner__img
		margin-bottom 20px
		width 240px
	.blog-item-banner__content
		flex-direction column-reverse
		align-items: flex-start;
		padding: 60px 20px
	.blog-item-banner
		background-image: url(../img/backgrounds/bg-banner-certeficate-mob.png), url(../img/backgrounds/bg-banner-certeficate-vegs-mob.svg), linear-gradient(89.84deg, #e13e34 .12%,#ff7144 48.95%,#ee5540 79.08%,#e13e34 99.86%);
		background-size: contain, cover
		background-repeat: no-repeat;
		background-position: bottom center;
	.blog-item-banner h3
		font-size 24px