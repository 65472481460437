// -----------------------------------------------
// Animations
// -----------------------------------------------
@keyframes spin
	100%
		transform: rotate(360deg)

@keyframes pulse
	from
		filter: hue-rotate(-60deg)
	to
		filter: hue-rotate(0deg)

@keyframes shaking
	from
		padding-left: 5px
	to
		padding-right: 5px

@keyframes twist
	from
		transform: rotate(0deg)
	to
		transform: rotate(360deg)
@keyframes move
	0%
		left: 0;
		opacity: 0
	5%
		opacity: .05
	48%
		opacity: .2
	80%
		opacity: 0
	to
		left: 75%
@keyframes fadeInRight
	from
		opacity: 0
		transform: translate3d(100%,0,0)
	to
		opacity: 1
		transform: none
