.form__input
	/* ------ label stuff ----------------------------------------------------- */
	&-label:empty
		display: none

	&-label
		display: block
		width: 100%
		font-size: 12px
		line-height: 14px
		color: $primary-gray
		text-align: left
		padding: 5px 0

	/* ------ disabled inputs after label ------------------------------------- */
	&-label + input[type='text']:disabled,
	&-label + input[type='password']:disabled,
	&-label + input[type='email']:disabled,
	&-label + input[type='tel']:disabled,
	&-label + textarea:disabled
		color: $primary-gray
		border-color: $primary-gray
		background-color: rgba(196,196,196,.25)
		cursor: default

	/* ----- error ------------------------------------------------------------ */
	&.error
		&-label + input[type='text'],
		&-label + input[type='password'],
		&-label + input[type='email'],
		&-label + input[type='tel'],
		&-label + textarea
			border-color: $primary-red

	/* ----- success ---------------------------------------------------------- */
	&.success
		&-label + input[type='text'],
		&-label + input[type='password'],
		&-label + input[type='email'],
		&-label + input[type='tel'],
		&-label + textarea
			border-color: $primary-green
