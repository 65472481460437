@media all and (max-width: 350px)
	.container
		padding 0 10px

	.fit-menu-tab
		&:not(:last-child)
			margin-right 0
	.test-menu-order__wrapper
		padding 30px 10px

	/* time counter */
	.counter span span
		font-size 25px
