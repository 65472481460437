.blog-item-banner
	width 100%
	background-image url(../img/backgrounds/bg-banner-certeficate.png), url(../img/backgrounds/bg-banner-certeficate-vegs.svg), linear-gradient(89.84deg, #E13E34 0.12%, #FF7144 48.95%, #EE5540 79.08%, #E13E34 99.86%)
	background-size cover
	background-position bottom center
	margin-top 40px
	&__content
		display flex
		align-items center
		padding 40px 0
		color #ffffff
	& h3
		font-size 36px
	& p
		font-size 18px
	& .button--t3
		&:hover
			background-color: #ffd601
