// -----------------------------------------------
// Component - Loader
// -----------------------------------------------

.dot{
	width:100px;
	height:100px;
	animation:rotation 2.5s ease infinite
	margin-bottom 15px
}

@keyframes rotation{
	from{transform:rotate(0deg)}
	to{transform:rotate(360deg)}
}

.dot:before{
	background-repeat:no-repeat;
	background-size:20px 20px;
	width:100%;
	height:100%;
	display:block;
	position:absolute;
	content:"";
	border-radius:50%;
	animation:dot 2.5s ease infinite
}

@keyframes dot{
	0%,95%,100%{
		background-image:
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0));
		background-position:
				center top,59px 5px,73px 20px,right center,73px 59px,59px 73px,center bottom
	}
	3%{
		background-image:
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				none
	}
	12%{
		background-image:
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				none,none
	}
	15%{
		background-image:
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				none,none,none
	}
	19%{
		background-image:
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				none,none,none,none
	}
	25%{
		background-image:
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				none,none,none,none,none
	}
	28%{
		background-image:
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0)),
				none,none,none,none,none,none
	}
	31%{
		background-image:
				none,none,none,none,none,none,none
	}
	32%{
		background-image:
				none,none,none,none,none,none,
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0))
	}
	40%{
		background-image:
				none,none,none,none,none,
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0))
	}
	43%{
		background-image:
				none,none,none,none,
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0))
	}
	54%{
		background-image:
				none,none,none,
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0))
	}
	64%{
		background-image:
				none,none,
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0))
	}
	72%{
		background-image:
				none,
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0)),
				radial-gradient(closest-side at 50% 50%,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,#cccccc,rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0),rgba(0,0,0,0))
	}
}
