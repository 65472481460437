.test-menu-order
	&__wrapper
		padding 40px 60px
		background url(../img/backgrounds/bg_test-order_menu.svg) top center no-repeat
		background-size cover
		& .fit-menu-tab__name h3
			text-transform unset
		& h3
			line-height 1
			text-transform uppercase
			text-align center
			margin-bottom 0
		& p
			font-size 16px
			text-align center
		& .fit-menu-tab__name span
			font-size 12px
			color #272621
	& .fit-menu-tab
		max-width 150px
		border-radius 5px
		& a
			display block
			width 100%
			height 100%

	& .fit-menu-tab__img
		height 70px
	& .tariff__order
		height 280px
		width 240px
		border 2px solid #fbf9f9
	& .tariff__delivery span
		color #000000
