.article
	width: 100%
	&__title
		font-size 30px
		text-align center
	&__footer
		display flex
		justify-content space-between
		align-items center
		margin-top 50px
	&__item-date, &__item-author
		display flex
		align-items center
		line-height 1
		& span
			margin-top 5px
		& i
			position relative
			width 25px
			height 25px
			margin-right 10px
			font-size 20px
			&:before
				position absolute
				right 0
				tpo 0
				z-index 1
			&:after
				content ''
				position absolute
				left 0
				bottom 0
				z-index 0
				display block
				width 18px
				height 18px
				border-radius 9px
				background $primary-yellow
	&__content
		h1
			//font-size: 24px

		h2
			//font-size: 30px

		h3
			font-size: 18px

		h4
			//font-size: 18px

		h5
			//font-size: 16px

		h6
			//font-size: 14px

		p
			word-break: break-word
			&:first-of-type
				&:first-letter
					font-size 60px
					line-height 1
					font-weight bold
					float left
					margin-right 10px

		ul, ol, img, table, iframe, address, em
			margin-bottom: 20px

		address, em
			font-style: italic

		iframe
			position: relative
			margin-top: 20px
			width: 100%
			height: 640px

		table
			width: 100% !important
			word-break: break-word

		img
			margin-bottom 25px

		a
			display: block
			overflow: hidden
			max-width: 100%
			text-decoration: underline
			text-overflow: ellipsis
			white-space: nowrap
			word-wrap: normal
			&:hover
				text-decoration: none

		ul, ol
			margin-left: 20px

		li
			display: list-item

		hr
			margin-bottom: 20px

		iframe
			width 100%
			height 100%
			box-sizing border-box
			transform-origin center top
			transition transform .05s linear
			backface-visibility hidden
			position absolute
			top:0
			right 0
			left 0
			bottom 0

		.videowrapper
			position relative
			overflow hidden
			padding-top 56.25%
			margin-bottom 25px
