// -----------------------------------------------
// Component - Socials
// -----------------------------------------------
.socials
	text-align center

	&__list
		display: flex
		align-items: center
		justify-content center

	&__item
		margin 0 10px

		&:first-child
			margin-left 0

		&:last-child
			margin-right 0

		&-link
			display: block
			font-size 32px
			color inherit
			text-decoration: none
			transition: $t-color
			cursor: pointer

			&:hover,
			&.active
				color $primary-yellow

		&-link .icon-twitter
			font-size: 20px
