// -----------------------------------------------
// Fonts
// -----------------------------------------------

@font-face
    font-family: "FitKitchen";
    src: url('../fonts/AvenirNextLTWGL-Regular.eot');
    src: url('../fonts/AvenirNextLTWGL-Regular.eot?#iefix') format('eot'),
            url('../fonts/AvenirNextLTWGL-Regular.woff') format('woff'),
            url('../fonts/AvenirNextLTWGL-Regular.ttf') format('truetype'),
            url('../fonts/AvenirNextLTWGL-Regular.svg?#AvenirNextLTWGL-Regular') format('svg');
    font-weight normal
    font-style normal
@font-face
	font-family: "FitKitchen";
	src: url('../fonts/AvenirNextLTWGL-Medium.eot');
	src: url('../fonts/AvenirNextLTWGL-Medium.eot?#iefix') format('eot'),
			url('../fonts/AvenirNextLTWGL-Medium.woff') format('woff'),
			url('../fonts/AvenirNextLTWGL-Medium.ttf') format('truetype'),
			url('../fonts/AvenirNextLTWGL-Medium.svg?#AvenirNextLTWGL-Medium') format('svg');
	font-weight 500
	font-style normal
@font-face
    font-family: "FitKitchen";
    src: url('../fonts/AvenirNextLTWGL-Bold.eot');
    src: url('../fonts/AvenirNextLTWGL-Bold.eot?#iefix') format('eot'),
            url('../fonts/AvenirNextLTWGL-Bold.woff') format('woff'),
            url('../fonts/AvenirNextLTWGL-Bold.ttf') format('truetype'),
            url('../fonts/AvenirNextLTWGL-Bold.svg?#AvenirNextLTWGL-Bold') format('svg');
    font-weight bold
    font-style normal
