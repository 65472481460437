/***
*
* Форма обратной связи
*
***/
.form--feedback
	display flex
	align-items center
	max-width 300px
	margin 0 auto
