.subscribe
	&-succes-wrapper, &-error-wrapper
		display flex
		justify-content center
		align-items center
		flex-direction column
		width 500px
		height 350px
		padding 30px

		background-size cover
		text-align center
		& h3
			font-size 30px
			margin-bottom 10px
		& p
			color #898881
	&-succes-wrapper
		background url(../img/backgrounds/modal-bg-success.svg) center no-repeat
		background-size contain
	&-error-wrapper
		background url(../img/backgrounds/bg-subscribe-error.png) center no-repeat
	&-error-img, &-success-img
		margin-bottom 20px


