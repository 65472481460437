.recent-posts
	padding 0 25px
	&__list
		margin-left 20px
		& li
			display list-item
			list-style disc
			margin-bottom 25px
		& a
			text-decoration underline
			&:hover
				text-decoration none