.blog
	&__list
		display flex
		flex-wrap wrap
		justify-content space-between
		@supports (display: grid)
			display grid
			grid-template-columns repeat(2,1fr)
			grid-gap 40px
.post
	overflow hidden
	box-shadow 0 20px 70px rgba(0,0,0,.05)
	border-radius 10px
	background-color #fff
	&__wrapper
		padding 30px 40px
		min-height 225px
		text-align left
	&__title
		min-height 75px
		font-size 20px
	&__img
		padding-bottom 56%
		position relative
		img
			height 100%
			width 100%
			border-radius 10px 10px 0 0
			object-fit cover
			position absolute
			top: 0
			left: 0
			&.no-photo
				object-fit scale-down
				box-shadow inset 0 0 1px #000

	&__description
		line-height 1.2
	&__footer
		display flex
		justify-content flex-end
		align-items center
