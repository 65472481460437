.allergens
	&-wrapper
		max-width 600px
		padding 40px
		background url(../img/backgrounds/bg-modal-allergens-top.svg) top center no-repeat, url(../img/backgrounds/bg-modal-allergens-bottom.svg) bottom right no-repeat
		background-size 80%
		& h3
			font-size 30px
			margin-bottom 10px
			line-height 1
			text-align center
	&__subtitle
		color #898881
		margin-bottom 35px
		text-align center
	&__item
		display flex
		align-items center
		margin-bottom 20px
		&-number
			position absolute
			top -15px
			left -15px
			text-align center
			line-height 29px
			font-weight 500
			width 30px
			height 30px
			border-radius 15px
			border 2px solid #ffffff
			background #FFD600
	&__img
		position relative
		flex-shrink 0
		margin-right 20px
	&__description
		& h4
			font-size 24px
			font-weight 400
			line-height 1
			margin-bottom 10px
		& p
			color #898881
			margin-bottom 0
