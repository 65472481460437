.swiper-container
	position: relative
	z-index: 1
	overflow: hidden
	margin: 0 auto
	padding: 0
	list-style: none

.swiper-container-no-flexbox .swiper-slide
	float: left

.swiper-container-vertical > .swiper-wrapper
	flex-direction: column

.swiper-wrapper
	position: relative
	z-index: 1
	display: flex
	box-sizing: content-box
	width: 100%
	height: 100%
	transition-property: transform

.swiper-container-android .swiper-slide, .swiper-wrapper
	transform: translate3d(0, 0, 0)

.swiper-container-multirow > .swiper-wrapper
	flex-wrap: wrap

.swiper-container-free-mode > .swiper-wrapper
	margin: 0 auto
	transition-timing-function: ease-out

.swiper-slide
	position: relative
	flex-shrink: 0
	width: 100%
	height: 100%
	transition-property: transform

.swiper-invisible-blank-slide
	visibility: hidden

.swiper-container-autoheight, .swiper-container-autoheight .swiper-slide
	height: auto

.swiper-container-autoheight .swiper-wrapper
	align-items: flex-start
	transition-property: transform, height

.swiper-container-3d
	perspective: 1200px

.swiper-container-3d .swiper-wrapper, .swiper-container-3d .swiper-slide, .swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom, .swiper-container-3d .swiper-cube-shadow
	transform-style: preserve-3d

.swiper-container-3d .swiper-slide-shadow-left, .swiper-container-3d .swiper-slide-shadow-right, .swiper-container-3d .swiper-slide-shadow-top, .swiper-container-3d .swiper-slide-shadow-bottom
	position: absolute
	top: 0
	left: 0
	z-index: 10
	width: 100%
	height: 100%
	pointer-events: none

.swiper-container-3d .swiper-slide-shadow-left
	background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))

.swiper-container-3d .swiper-slide-shadow-right
	background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))

.swiper-container-3d .swiper-slide-shadow-top
	background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))

.swiper-container-3d .swiper-slide-shadow-bottom
	background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0))

.swiper-container-wp8-horizontal, .swiper-container-wp8-horizontal > .swiper-wrapper
	touch-action: pan-y

.swiper-container-wp8-vertical, .swiper-container-wp8-vertical > .swiper-wrapper
	touch-action: pan-x

.swiper-button-prev, .swiper-button-next
	position: absolute
	top: 50%
	z-index: 10
	margin-top: -22px
	width: 27px
	height: 44px
	background-position: center
	background-size: 27px 44px
	background-repeat: no-repeat
	cursor: pointer

.swiper-button-prev.swiper-button-disabled, .swiper-button-next.swiper-button-disabled
	opacity: 0.35
	cursor: auto
	pointer-events: none

.swiper-button-prev, .swiper-container-rtl .swiper-button-next
	right: auto
	left: 0
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E")

.swiper-button-next, .swiper-container-rtl .swiper-button-prev
	right: 0
	left: auto
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23007aff'%2F%3E%3C%2Fsvg%3E")

.swiper-button-prev.swiper-button-white, .swiper-container-rtl .swiper-button-next.swiper-button-white
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")

.swiper-button-next.swiper-button-white, .swiper-container-rtl .swiper-button-prev.swiper-button-white
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E")

.swiper-button-prev.swiper-button-black, .swiper-container-rtl .swiper-button-next.swiper-button-black
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")

.swiper-button-next.swiper-button-black, .swiper-container-rtl .swiper-button-prev.swiper-button-black
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E")

.swiper-button-lock
	display: none

.swiper-pagination
	position: absolute
	z-index: 8
	text-align: center
	transition: 300ms opacity
	transform: translateX(-50%)
	left 50%
	bottom 15px

.swiper-pagination.swiper-pagination-hidden
	opacity: 0

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets
	bottom: 10px
	left: 0
	width: 100%

.swiper-pagination-bullets-dynamic
	overflow: hidden
	font-size: 0

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet
	position: relative
	transform: scale(0.33)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active
	transform: scale(1)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev
	transform: scale(0.66)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev
	transform: scale(0.33)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next
	transform: scale(0.66)

.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next
	transform: scale(0.33)

.swiper-pagination-bullet
	display: inline-block
	width: 8px
	height: 8px
	border-radius: 100%
	background: black
	margin: 0 15px
	opacity .25

button.swiper-pagination-bullet
	margin: 0
	padding: 0
	border: none
	box-shadow: none
	appearance: none

.swiper-pagination-clickable .swiper-pagination-bullet
	cursor: pointer

.swiper-pagination-bullet-active
	background: #000000
	opacity: 1

.swiper-container-vertical > .swiper-pagination-bullets
	top: 50%
	right: 10px
	transform: translate3d(0, -50%, 0)

.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet
	display: block
	margin: 6px 0

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
	top: 50%
	width: 8px
	transform: translateY(-50%)

.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet
	display: inline-block
	transition: 200ms transform, 200ms top

.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet
	margin: 0 4px

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic
	left: 50%
	white-space: nowrap
	transform: translateX(-50%)

.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet
	transition: 200ms transform, 200ms left

.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet
	transition: 200ms transform, 200ms right

/* Progress */
.swiper-pagination-progressbar
	position: absolute
	background: rgba(0, 0, 0, 0.25)

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill
	position: absolute
	top: 0
	left: 0
	width: 100%
	height: 100%
	background: #007aff
	transform: scale(0)
	transform-origin: left top

.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill
	transform-origin: right top

.swiper-container-horizontal > .swiper-pagination-progressbar
	top: 0
	left: 0
	width: 100%
	height: 4px

.swiper-container-vertical > .swiper-pagination-progressbar
	top: 0
	left: 0
	width: 4px
	height: 100%

.swiper-pagination-white .swiper-pagination-bullet-active
	background: white

.swiper-pagination-progressbar.swiper-pagination-white
	background: rgba(255, 255, 255, 0.25)

.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill
	background: white

.swiper-pagination-black .swiper-pagination-bullet-active
	background: black

.swiper-pagination-progressbar.swiper-pagination-black
	background: rgba(0, 0, 0, 0.25)

.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill
	background: black

.swiper-pagination-lock
	display: none

.swiper-scrollbar
	position: relative
	border-radius: 10px
	background: rgba(0, 0, 0, 0.1)
	-ms-touch-action: none

.swiper-container-horizontal > .swiper-scrollbar
	position: absolute
	bottom: 3px
	left: 1%
	z-index: 50
	width: 98%
	height: 5px

.swiper-container-vertical > .swiper-scrollbar
	position: absolute
	top: 1%
	right: 3px
	z-index: 50
	width: 5px
	height: 98%

.swiper-scrollbar-drag
	position: relative
	top: 0
	left: 0
	width: 100%
	height: 100%
	border-radius: 10px
	background: rgba(0, 0, 0, 0.5)

.swiper-scrollbar-cursor-drag
	cursor: move

.swiper-scrollbar-lock
	display: none

.swiper-zoom-container
	display: flex
	justify-content: center
	align-items: center
	width: 100%
	height: 100%
	text-align: center

.swiper-zoom-container > img, .swiper-zoom-container > svg, .swiper-zoom-container > canvas
	max-width: 100%
	max-height: 100%
	object-fit: contain

.swiper-slide-zoomed
	cursor: move

.swiper-lazy-preloader
	position: absolute
	top: 50%
	left: 50%
	z-index: 10
	margin-top: -21px
	margin-left: -21px
	width: 42px
	height: 42px
	transform-origin: 50%
	animation: swiper-preloader-spin 1s steps(12, end) infinite

.swiper-lazy-preloader:after
	display: block
	width: 100%
	height: 100%
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E")
	background-position: 50%
	background-size: 100%
	background-repeat: no-repeat
	content: ''

.swiper-lazy-preloader-white:after
	background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E")

@keyframes swiper-preloader-spin
	100%
		transform: rotate(360deg)

@keyframes swiper-preloader-spin
	100%
		transform: rotate(360deg)

.swiper-container .swiper-notification
	position: absolute
	top: 0
	left: 0
	z-index: -1000
	opacity: 0
	pointer-events: none

.swiper-container-fade.swiper-container-free-mode .swiper-slide
	transition-timing-function: ease-out

.swiper-container-fade .swiper-slide
	transition-property: opacity
	pointer-events: none

.swiper-container-fade .swiper-slide .swiper-slide
	pointer-events: none

.swiper-container-fade .swiper-slide-active, .swiper-container-fade .swiper-slide-active .swiper-slide-active
	pointer-events: auto

.swiper-container-cube
	overflow: visible

.swiper-container-cube .swiper-slide
	z-index: 1
	visibility: hidden
	width: 100%
	height: 100%
	backface-visibility: hidden
	transform-origin: 0 0
	pointer-events: none

.swiper-container-cube .swiper-slide .swiper-slide
	pointer-events: none

.swiper-container-cube.swiper-container-rtl .swiper-slide
	transform-origin: 100% 0

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-active .swiper-slide-active
	pointer-events: auto

.swiper-container-cube .swiper-slide-active, .swiper-container-cube .swiper-slide-next, .swiper-container-cube .swiper-slide-prev, .swiper-container-cube .swiper-slide-next + .swiper-slide
	visibility: visible
	pointer-events: auto

.swiper-container-cube .swiper-slide-shadow-top, .swiper-container-cube .swiper-slide-shadow-bottom, .swiper-container-cube .swiper-slide-shadow-left, .swiper-container-cube .swiper-slide-shadow-right
	z-index: 0
	backface-visibility: hidden

.swiper-container-cube .swiper-cube-shadow
	position: absolute
	bottom: 0
	left: 0
	z-index: 0
	width: 100%
	height: 100%
	background: black
	filter: blur(50px)
	opacity: 0.6

.swiper-container-flip
	overflow: visible

.swiper-container-flip .swiper-slide
	z-index: 1
	backface-visibility: hidden
	pointer-events: none

.swiper-container-flip .swiper-slide .swiper-slide
	pointer-events: none

.swiper-container-flip .swiper-slide-active, .swiper-container-flip .swiper-slide-active .swiper-slide-active
	pointer-events: auto

.swiper-container-flip .swiper-slide-shadow-top, .swiper-container-flip .swiper-slide-shadow-bottom, .swiper-container-flip .swiper-slide-shadow-left, .swiper-container-flip .swiper-slide-shadow-right
	z-index: 0
	backface-visibility: hidden

.swiper-container-coverflow .swiper-wrapper
	-ms-perspective: 1200px
