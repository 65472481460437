// -----------------------------------------------
// Module - Navigation sidebar
// -----------------------------------------------

.sidebar
	width 100%
	&__widget
		margin-bottom 40px
	&__title
		margin-bottom 20px
		font-size 18px
		line-height 1

