/***
*
* Главный лоадер при загрузке страницы
*
***/



.loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: #ffcd00;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 9999;
	flex-wrap: wrap;
	overflow:hidden;
}

.loader  img {
	width: 100px;
}
.lent {
	height: 200px;
	width: 10000px;
	animation: lenta 10s;

	animation-iteration-count: infinite;
	animation-timing-function: linear;
}
.lent_load_wrap {
	width: 525px;
	height: 200px;
	display: block;
	overflow: hidden;
	margin: 0 auto;
}

.loader.hidden {
	animation: fadeOut 1s;
	animation-fill-mode: forwards;
	display: none;
}
.line_b{
	animation: len_prog 9s;
	animation-fill-mode: forwards;
}
.txt_line svg {
	height: 20px;
}
.txt_line {
	width: 100%;
	text-align: center;
	margin-bottom: 20px;
}
.txt_line_wrap {
	overflow: hidden;
	height: 20px;
}
@keyframes fadeOut {
	99% {
		opacity: 0;
		visibility: hidden;
	}
	100% {
		display: none;
	}
}
@keyframes lenta {
	0% {
		transform:  translate(-1000px,0px)  rotate(0deg) ;
	}
	100% {    transform: translate(-520px,0px) rotate(0deg);
	}
}

@keyframes len_prog {
	0% {
		width:0%;
	}
	60% {
		width:80%;
	}
	89% {
		width:90%;
	}
	97% {
		width:97%;
	}
	100% {
		width:98%;
	}
}


@keyframes txt1 {
	0% {top:0px;
	}
	25% {
		top:0px;
	}
	35% {
		top:-44px;
	}
	55% {
		top:-44px;
	}

	60% {
		top:-88px;
	}
	75% {
		top:-88px;
	}
	80% {
		top:-132px;
	}
	100% {
		top:-132px;
	}

}
.txt_line	{
	animation: txt1 10s;
	position:relative;
}


@keyframes slide-in-bottom {
	0% {
		-webkit-transform: translateY(100px);
		transform: translateY(100px);
		opacity: 0;
	}
	100% {
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1;
	}
}

svg.green {
	position: absolute;
	max-width: 100px;
	animation: slide-in-bottom 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
svg.green:nth-child(1) {
	top: 95%;
	left: 39%;
	animation-duration: 1s;
	max-width: 150px;
}
svg.green:nth-child(2) {
	top: 83%;
	left: 11%;
	animation-duration: 0.4s;
	max-width: 85px;
}
svg.green:nth-child(3) {
	top: 58%;
	right: 6%;
	animation-duration: 0.6s;
	max-width: 85px;
}
svg.green:nth-child(4) {
	top: 17%;
	left: 16%;
	animation-duration: 0.2s;
}
svg.green:nth-child(5) {
	top: 88%;
	right: 11%;
	max-width: 130px;
}
svg.green:nth-child(6) {
	top: 14%;
	right: 12%;
	max-width: 130px;
}

@media only screen and (max-width: 768px){
	.loader img {
		width: 95px;
		margin-bottom: 30px;
	}
	.lent_load_wrap {
		height: 135px;
	}
	.lent {
		height: 135px;
		background-size: contain;
	}
	svg.green:nth-child(3), svg.green:nth-child(4), svg.green:nth-child(5), svg.green:nth-child(6) {
		display:none;
	}
}
