/***
*
* Форма авторизации
*
***/
.form--login
	display flex
	align-items center
	max-width 300px
	margin 0 auto
