/***
*
* Phone plugin stuff
*
***/

// ----- disabled -----
.form__input--phone.disabled
	& .iti__flag-container
		color: #747474
		cursor: default !important
		pointer-events:none

	& .iti__flag,
	& .iti__arrow
		opacity: .35

	& input[data-intltelinput]
		color: $primary-gray
		border-color: $primary-gray
		background-color: rgba(196,196,196,.25)
		pointer-events: none
		cursor: default

		&:focus
			border-color: inherit
			color: inherit
