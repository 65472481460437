.section.section--youtube
	background #ffffff
	padding-bottom 0
	& .section__header
		margin: 0 auto
		padding: 0 25px
		max-width: 1210px
	& .container
		max-width 100%
		padding 0
.slider-youtube
	position: relative
	margin-top 50px
.slider-youtube__button-prev, .slider-youtube__button-next
	display: flex
	justify-content: center
	align-items: center
	width: 60px
	height: 60px
	font-size: 12px
	color: #000
	border: 1px solid rgba(255,255,255,0.3)
	box-shadow: 0 0 12px #e3e2e2
	border-radius: 30px
	cursor: pointer
	flex-shrink: 0
	transition: all .5s
	&:hover
		box-shadow: 0 0 12px #9a9a9a
.slider-youtube__button-prev
	left 0
.slider-youtube__button-next
	right 0

.youtube

	&__post
		max-width 620px


.youtube__post
	opacity .2
	transition: opacity .5s
	pointer-events none
	&.swiper-slide-active
		opacity 1
		pointer-events auto
		& .youtube__post-link
			position relative
			display flex
			width 100%
			height 100%
			background #000
			padding-bottom: 56%
			& img
				height: 100%
				width: 100%
				object-fit: cover
				position: absolute
				top: 0
				left: 0
			&:before
				content ''
				position absolute
				top: 50%
				left: 50%
				z-index 1
				transform: translate(-50%, -50%)
				display flex
				width 64px
				height 64px
				background url(../img/elements/play-button.svg) no-repeat center
				background-size contain
				opacity 0
				transition opacity .5s
			&:after
				content ''
				position absolute
				display block
				width 100%
				height 100%
				background rgba(0, 0, 0, 0.5)
				opacity 0
				transition opacity .5s

			&:hover
				&:before, &:after
					opacity 1