.widgets
	display flex
	justify-content space-around
	align-items center
	flex-wrap wrap

	&__title
		display block
		width 100%
		font-size 38px
		font-weight 500
		text-align center
		text-transform uppercase
		margin-bottom 25px

	&__item
		width 100%
		max-width 830px
