/***
*
* Кастомный скроллбар
*
***/
.scroll
	&-wrapper
		position relative
		padding 0 !important
		overflow hidden !important
	&-content
		position relative !important
		top 0
		left 0
		width auto !important
		max-width none !important
		height auto
		max-height none
		padding 0
		margin 0
		border none !important
		box-sizing content-box !important
		overflow scroll !important
		&::-webkit-scrollbar
			width 0
			height 0
	&-content&-scrolly_visible
		padding-right 18px
	&-element
		display none
		&_outer
			top 30px !important
			overflow hidden
		&_inner
			top -60px
		&_track
			top -60px !important
			left 2px
			width 2px !important
			background-color $primary-gray
		&_size
			top -60px !important
	&-element_size,	&-element_inner-wrapper, &-element_inner, &-bar, &-bar-body, &-bar-body-inner, &-y, &-element_outer
		position absolute
		top 0
		right 0
		display block
		width 100%
		height 100%
	&-bar
		background-color #c4c4c4
		border-radius 8px
		&:hover
			background-color darken(#c4c4c4, 5)
	&-draggable &-bar
		background-color darken(#c4c4c4, 10)
	&-y
		display none
		top 0
		right 6px
		width 6px
		height 100%
	&-scrolly_visible&-y&-element_arrows_visible
		display block
	&--void &-content
		padding-right 10px

	// стрелочки вверх/вниз
	&-arrow
		position absolute
		left -3px
		z-index 11
		width 20px
		height 20px
		cursor pointer
		&::before
			content ''
			display block
			width 13px
			height 13px
			border-left 1px solid $primary-gray
			border-bottom 1px solid $primary-gray
		&:hover::before
			border-color darken($primary-gray, 10)
		&_less
			top 15px
			&::before
				transform rotate(135deg)
		&_more
			bottom 5px
			&::before
				transform rotate(-45deg)
