// -----------------------------------------------
// Module - Header
// -----------------------------------------------
.header
	position: fixed
	top: 0
	width: 100%
	background-color: #ffd600
	z-index: 10

	&__layout
		position: relative
		display: flex
		//flex-wrap wrap
		justify-content: space-between
		height: 70px
		max-width 1160px
		margin auto
	&--left, &--middle, &--right
		display: flex
		align-items center
	&--left
		justify-content: flex-start
		width auto
		& [data-subheader]
			margin-right 25px
	&--right
		justify-content: flex-end
		width auto
	&-info
		margin-left 50px
		display flex
		align-items center
		& p
			margin-bottom 0
			margin-right 30px
		& i
			font-size 20px
			margin-left 10px
			margin-bottom 5px
	&-logo
		display block
		& img
			height 49px
.sub-header
	background #fff
	width 100%
	position fixed
	top: 10px
	z-index 9
	border-radius: 0 0 60px 60px
	transition .4s
	&.visible
		box-shadow 0 6px 10px rgba(0,0,0,.11)
		top 70px
		transition .4s
	&-menu
		display flex
		justify-content center
		align-items center
		height 50px
		& li
			padding 10px 20px
			border-radius 25px
			margin 0 10px
			line-height 1
			transition background .4s
			&:hover
				background $primary-yellow