// -----------------------------------------------
// Container
// -----------------------------------------------

// Generic Variables
4k = 'all and (max-width: 3840px)'
xxl = 'all and (max-width: 1920px)'
mxl = 'all and (max-width: 1760px)'
sxl = 'all and (max-width: 1580px)'
ssxl = 'all and (max-width: 1440px)'
xl = 'all and (max-width: 1280px)'
lg = 'all and (max-width : 1024px)'
md = 'all and (max-width : 768px)'
sm = 'all and (max-width : 640px)'
xs = 'all and (max-width : 480px)'
xxs = 'all and (max-width : 320px)'
t = '(pointer:coarse)'

// -----------------------------------------------
.container
	margin: 0 auto
	padding: 0 25px
	max-width: 1210px
	width: 100%
.col-55
	width: 55%
	@media md
		width: 100%

.col-50
	width: 50%
	@media md
		width: 100%

.col-33
	width: 33%
	@media md
		width: 100%

.col-25
	width: 25%
	@media md
		width: 100%

.col-20
	width: 20%
	@media md
		width: 100%

.col-15
	width: 15%
	@media md
		width: 100%

.col-10
	width: 10%
	@media md
		width: 100%
