//-----------------------------------------------
// Component - Datepicker
//-----------------------------------------------
.datepicker,
.datepicker--pointer
  background-color: white
  border-color: $primary-gray

.datepicker--nav
  border-color: $primary-gray

.datepicker--day-name,
.datepicker--nav-title
  color: $primary-red

.datepicker--cell,
.datepicker--nav-action,
.datepicker--nav-title,
.datepicker--nav-title i
  transition: $t-color, $t-bg-color

.datepicker--cell.-selected-,
.datepicker--cell.-focus-,
.datepicker--cell.-focus-.-selected-,
.-selected-.datepicker--cell-day.-other-month-,
.-selected-.datepicker--cell-year.-other-decade-,
.datepicker--nav-action:hover,
.datepicker--nav-title:hover
  background-color: $primary-red
  color: white

.datepicker--nav-title:hover,
.datepicker--nav-title:hover i
  color: white
