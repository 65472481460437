.review
	&__list
		display flex
		flex-wrap wrap
		justify-content space-between
		@supports (display: grid)
			display grid
			grid-template-columns repeat(2, 1fr)
			grid-gap 40px
.review
	overflow hidden
	box-shadow 0 20px 70px rgba(0,0,0,.05)
	border-radius 10px
	background-color #fff
	&__wrapper
		padding 30px
		min-height 225px
		text-align center
	&__title
		margin-bottom 10px
		font-size 20px
	&__subtitle
		display block
		min-height 36px
		color #818789
		margin-bottom 10px
	&__img
		position relative
		width 100px
		height 100px
		margin auto
		margin-top 25px
		border-radius 50px
		overflow hidden
		flex-shrink 0
		img
			height 100%
			width 100%
			object-fit cover
			&.no-photo
				object-fit scale-down

	&__header
		display flex
		align-items center
		box-shadow 0 7px 20px rgba(128,128,128,.08)
		border-radius 15px
		margin-bottom 30px
		padding 10px
		& .review__img
			margin 10px
		& .review__subtitle
			color #000000
			min-height 0
			margin-bottom 0
	&__description
		line-height 1.2
		min-height 80px
	&__footer
		display flex
		justify-content center
		align-items center