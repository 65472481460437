.dots
	display flex
	margin 5px 0
	font-size 10px
	color #E4E5E6
	& i
		margin 0 3px
		text-shadow none
	&--1
		& i:first-child
			color #FFD600
	&--2
		& i:nth-child(-n+2)
			color #FFD600
	&--3
		& i:nth-child(-n+3)
			color #FFD600
	&--4
		& i:nth-child(-n+4)
			color #FFD600
	&--5
		& i:nth-child(-n+5)
			color #FFD600
	&--6
		& i:nth-child(-n+6)
			color #FFD600
