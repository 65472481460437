.fit-menu
	padding-top 20px
	&-tabs
		display none
		justify-content space-between
		align-items center
		margin-bottom 30px
		&.active
			display flex
		&__content--wrapper
			max-width 100%
			width 100%
	&-tab
		position relative
		display flex
		flex-direction column
		align-items center
		justify-content space-between
		//padding 15px 5px
		max-width: 200px
		width 100%
		//max-height: 170px
		height 200px
		//border-radius: 15px
		color: #272621
		//border: 2px solid #E1DFE2
		font-size: 15px
		text-align: center
		cursor pointer
		transition: all .4s
		&:before
			content ''
			position absolute
			top 30px
			display block
			width 0px
			height 0px
			border-radius 25px
			background-color #ffd600
			z-index 0
			transition: width .4s;
		&:not(:last-child)
			margin-right 10px
		& .promotion__value
			position absolute
			top -10px
			right -10px
			padding 5px 10px
			font-weight 700
			background #FF203B
			border-radius 6px
			z-index 1
			& p
				color #ffffff
				line-height 1
				margin-top 2px
				margin-bottom 0
		&__img
			height 100px
			margin-bottom 10px

			& img
				object-fit: scale-down
				width 100%
				height 100%


		& h3
			margin-bottom 0
			font-size 30px
			font-weight 700
			color #818789
			transition: color .4s;
		& span
			color #818789
		&:hover
			box-shadow: 0 20px 70px rgba(0,0,0,.05)
		&.active
			& .fit-menu-tab__wrapper
				color: #272621
				box-shadow: 0 20px 50px rgba(0, 0, 0, 0.1)
				border-color #ffffff
				background-color #ffffff

			//&:after
			//	content ''
			//	position absolute
			//	top 0
			//	width 100%
			//	height 100%
			//	display block
			//	border-radius 15px
			//	background-color #fff
			//	z-index -1

		&.active, &:hover
			&:before
				width 50px
				height 50px
			& h3
				color #000000
			& img
				opacity 1
				transition opacity .4s
	&__description
		text-align center
		margin-bottom 80px
		& h3
			color #282224
			margin-bottom 10px
			font-size 16px
		& p
			margin-bottom 0
			color #929292
			line-height 1
	&__content
		display grid
		grid-template-columns 3fr 1fr
		grid-gap 20px
		//& .swiper-container
		//	width 100%
	&__list
		display grid
		grid-template-columns repeat(3,1fr)
		grid-gap 20px
	&-item
		display flex
		flex-direction column
		border-radius 15px
		height 420px
		max-width 280px
		width 100%
		position relative
		background #ffffff
		box-shadow 0 1px 3px rgba(39,38,33,.1)
		&:hover
			& .eating
				left 0
				opacity 1
				visibility visible
				background-position 0 0
				&:before
					top 0
					left 0


		&__img
			padding-bottom 75%
			position relative
			border-radius 15px 15px 0 0
			overflow hidden
			& img
				position absolute
				top 0
				left 0
				height 100%
				width 100%
				object-fit cover
				&.no-photo
					object-fit: scale-down
					border-bottom 1px solid #ffd600
		&__description
			display flex
			flex-direction column
			justify-content space-between
			height 100%
			padding 20px 15px

		&__name
			font-size 15px
			height 60px
			& h3
				font-size 15px
				font-weight 400
				margin-bottom 0
		&__info
			display flex
			justify-content space-between
			align-items center
			flex-wrap wrap
		&__params
			display flex
			& li:not(:last-child)
				margin-right 25px
			& h6
				font-size 12px
				font-weight 400
				color #818789
	& .eating
		position absolute
		top 0
		left -120px
		display flex
		justify-content center
		align-items center
		height 80px
		width 120px
		opacity 0
		visibility hidden
		z-index 2
		transition left .6s
		& h3
			font-size 16px
			margin-bottom 0
		&:before
			content ''
			position absolute
			top -120px
			left 120px
			width 0
			height 0
			z-index -1
			border-style solid
			border-width 60px 0 30px 120px
			border-color #ffd600 transparent transparent #ffd600
			filter drop-shadow(0 0 5px #999)
			transition top .4s

	& .calories
		color #272621
		font-size 25px
		line-height 1
		font-weight 700
		&__total
			& p
				margin-bottom 0
				font-size 20px
	& .allergens
		width 100%
		flex-shrink 0
		color #818789
		margin-top 10px
	& .markers
		display flex
		justify-content flex-end
		align-items center
	& .icon-milk
		color #B3A187
	& .icon-flour
		color #E79A27
	& .icon-sugar
		color #75C8F6
	& .marker
		position relative
		margin-left 5px
		font-size 24px
		&:hover
			& .tooltiptext
				visibility visible
				opacity 1
		& .tooltiptext
			position absolute
			z-index 1
			bottom 125%
			left 50%
			visibility hidden
			width auto
			color #000000
			background-color #ffd600
			text-align center
			border-radius 6px
			padding 5px
			font-size 12px
			transform translateX(-50%)
			opacity 0
			transition opacity .3s
			white-space nowrap
			&:after
				content ''
				position absolute
				top 100%
				left 50%
				margin-left -5px
				border-width 5px
				border-style solid
				border-color #ffd600 transparent transparent

#fit-menu-container
	min-height 400px
	display flex
	flex-direction column
	align-items center
	justify-content center
.fit-menu-day__wrapper, .fit-menu-week__wrapper
	//display none
	max-height 0
	width 100%
	visibility hidden
	opacity 0
	transition opacity .3s
	&.active
		display block
		max-height unset
		visibility visible
		opacity 1
		transition opacity .3s
.fit-menu-tab img
	position relative
	width 90px
	height 90px
	object-fit scale-down
	margin-bottom 10px
	opacity .5
	filter grayscale(1)
	z-index 1
	transition opacity .4s
.fit-menu-tab__wrapper
	padding: 15px 5px
	width: 100%
	height: 100%
	border-radius: 15px
	border: 2px solid #e1dfe2
.fit-menu__description strong
	margin-bottom 10px
	color #000
	display inline-block
.without-meat
	& .fit-menu-tab
		&.active
			z-index 0
			&:after
				content ''
				position absolute
				top -20px
				display block
				z-index -1
				width 240px
				height 240px
				background-image: url(../img/backgrounds/bg_leaves.svg);
				background-position: bottom center;
				background-repeat: no-repeat;
				background-size: 100%;
		&.active, &:hover
			&:before
				top 5px
				width 115px
				height 115px
				background: radial-gradient(50% 50% at 50% 50%, #FFD600 0%, rgba(255, 214, 0, 0) 100%)
.section.section--fit-menu
	background #ffffff
	padding-top 0
	padding-bottom 0
.section--fit-menu__wrapper
	padding-top 15px
	padding-bottom 80px
	background #FAF9F4
.fit-menu-switcher
	display flex
	align-items center
	justify-content center
	height 70px
	width 200px
	max-width 50%
	font-weight: 750;
	font-size: 15px;
	line-height: 19px;
	color: #818789
	transition color .4s
	& img
		margin-right 10px
		filter grayscale(1)
		transition filter .4s
	&:hover
		cursor pointer
		color #000000
		& img
			filter grayscale(0)
	&.active
		color #000000
		background: #FAF9F4;
		border-radius: 15px 15px 0 0
		cursor unset
		& img
			filter grayscale(0)
	&__wrapper
		display flex
		align-items center
		justify-content center

