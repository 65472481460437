@media all and (max-width: 1220px)
	.header-info p
		margin-right 0
	.header-info
		margin-left 20px
		flex-shrink 0
	.slider-main__content, .slider-navigation
		padding 0 25px
	.section--subscribe__description
		font-size 38px
	.order-monthly
		padding 0
		flex-wrap wrap