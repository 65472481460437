// -----------------------------------------------
// Component - Slider
// -----------------------------------------------

.slider-navigation
	position: absolute
	bottom: 30px
	z-index: 1
	max-width: 1160px
	margin: auto
	left: 0
	right: 0
	display flex

.slider
	&__button
		display flex
		justify-content center
		align-items center
		width 60px
		height 60px
		color #ffffff
		border 1px solid rgba(255, 255, 255, 0.3)
		border-radius 30px
		cursor pointer
		transition: all .5s
		&-prev
			margin-right 40px
		&:hover
			box-shadow: 0 0 12px #000
	&-section__button
		display flex
		justify-content center
		align-items center
		width 60px
		height 60px
		font-size 12px
		color #000000
		border 1px solid rgba(255, 255, 255, 0.3)
		box-shadow 0 0 12px #e3e2e2
		border-radius 30px
		cursor pointer
		flex-shrink 0
		transition: all .5s
		&:hover
			box-shadow: 0 0 12px #9a9a9a
// -----------------------------------------------------------------------------
.section__header .swiper-pagination
	position relative
	z-index auto
	height 9px
	margin-right 180px
	text-align left
	line-height 9px
	white-space normal

	&-bullet
		display inline-block
		width 40px
		height 9px
		margin 0 5px
		background-color rgba(35,35,35,0.2)
		border-radius 20px
		cursor pointer
		&-active
			background-color $primary-red

// -----------------------------------------------------------------------------
.slider--disabled
	.swiper
		&-wrapper
			transform translate3d(0px, 0, 0) !important

		&-pagination
			display none

		&-arrow
			display none
