/***
*
* Счетчик обратного отсчета)
*
***/
.countdown
	//position absolute
	//right 0
	//bottom -40px
	height 40px
	width 280px
	display flex
	justify-content center
	align-items center
	border-radius 0 0 10px 10px
	color #ffffff
	background url(../img/backgrounds/bg-countdown.png) center no-repeat, #272621
	box-shadow 0 1px 3px rgba(39,38,33,0.1)
	& h5
		font-size 12px
		line-height 1
		margin-bottom 0
		margin-right 10px
		color $primary-yellow
.counter
	display inline-flex
	justify-content center
	text-align center
	& span
		font-weight 700
		font-size 26px
		line-height 25px
		padding-top 2px


