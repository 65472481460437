// -----------------------------------------------
// Styles - for FAQ page
// -----------------------------------------------

.faq
	max-width 825px
	margin 0 auto
	padding-top 30px
	&__item
		width 100%
		color #000000
		border-bottom: 2px solid #efeeec
	&__question
		display list-item
		font-size 20px
		font-weight bold
		margin-left 20px
		padding: 35px 35px 35px 0
		margin-bottom 0
		position relative
		cursor pointer
		&:before,
		&:after
			content: ''
			box-sizing: border-box
			position: absolute
			right: 8px
			top: 50%
			height: 2px
			width: 13px
			background: #282224
			backface-visibility: hidden
			transition-property: transform
			transition-duration: .2s

		&:before
			transform: rotate(45deg)
			right: 16px

		&:after
			transform: rotate(-45deg)

	&__answer
		display none
		padding-bottom 15px
		line-height 1.2

	/* open state */
	&--open
		& .faq__question:before
			transform: rotate(-45deg)
		& .faq__question:after
			transform: rotate(45deg)

	&-tab01
		background-image: url(../img/backgrounds/tab-faq01.svg)
	&-tab02
		background-image: url(../img/backgrounds/tab-faq02.svg)
	&-tab03
		background-image: url(../img/backgrounds/tab-faq03.svg)
	&-tab04
		background-image: url(../img/backgrounds/tab-faq04.svg)
	&-tab05
		background-image: url(../img/backgrounds/tab-faq05.svg)
	&-tabs
		display flex
		justify-content space-between
		align-items center
		font-size 20px
		font-weight bold
		margin-bottom 30px
		& li
			display flex
			align-items center
			justify-content center
			width: 150px
			height: 130px
			border-radius: 10px
			text-align: center
			border 2px solid #cccccc
			cursor pointer
			&.active
				border-color $primary-yellow
		&__content
			display none
			&.active
				display block
