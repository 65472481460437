/*
меню выбора языка в шапке сайта
*/
.lang
	flex-shrink: 0
	width: 75px
	height: 30px
	margin-left: 10px

	&-select
		display flex
		flex-direction column
		height 30px
		color black
		border-radius 4px
		cursor pointer
		overflow hidden
		transition all 150ms ease-in-out
		position: relative

		/* item styles for positioning */
		&__item
			display flex
			align-items center
			order 2
			user-select none
			position relative
			z-index 1

			/* choosen language */
			&.active
				order 1
				pointer-events none

				/* arrow */
				&:before
					content ''
					width 0
					height 0
					border-top 8px solid $primary-gray
					border-left 6px solid transparent
					border-right 6px solid transparent
					position absolute
					top 10px
					right 5px
					z-index 2

		/* base item styles */
		&__item
			min-height: 30px

			&-link
				display: flex
				width: 100%
				padding: 5px

				&:hover
					background-color rgba(#ccc, 50%)

			img
				display block
				width 25px
				border 1px solid $primary-gray
				box-sizing border-box
				overflow hidden

			&-text
				display flex
				justify-content center
				align-items center
				width 20px
				height 20px
				margin-left 5px
				margin-right 5px
				font-size 12px
				color $primary-red

		/* when select is open */
		&.open
			height: auto
			background-color: white
			box-shadow: $primary-box-shadow
			transition: all .15s ease-out
			z-index: 1

		&.open &__item-text
			color black

		&.open &__item.active:before
			top 10px
			transform rotate(180deg)
