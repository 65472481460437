/***
*
* Selectric default stuff
*
***/
.selectric .label
	font-weight: normal

// ----- default: disabled ------
.selectric-wrapper.selectric-disabled
	opacity: 1

	& .selectric
		background-color: rgba(196,196,196,.25)
		border-color: $primary-gray

		& .label
			color: $primary-gray

		& .button
			opacity: .45
