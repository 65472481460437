.calculator
	&-calories
		text-align center
		&__result
			display none
			flex-direction column
			justify-content center
			align-items center
			padding 50px 25px
			border-radius 15px
			background-image url(../img/backgrounds/bg-colories-result.svg)
			background-position center
			background-size contain
			background-repeat no-repeat
			background-color $primary-yellow
			&-buttons
				display flex
				flex-direction column
				justify-content center
				align-items center
			& p
				font-size 20px
			& span
				font-size 18px
			& h4
				font-size 40px
				margin-bottom 0
			& .button
				margin-top 30px
				margin-bottom 10px
				box-shadow 0 10px 20px rgba(123,122,122,.4)
				&:before
					animation none


	&-step
		display flex
		flex-direction column
		justify-content center
		align-items center
		margin 40px 0

		&__buttons
			display flex
			flex-wrap wrap
			justify-content center
			align-items center
			max-width 840px
			margin auto

		&__inputs
			display flex
			flex-wrap wrap
			justify-content space-around
			align-items center
			padding 0 25px
			width 100%
			& .form__input
				width 100%
				max-width 340px
				margin 5px 10px
				& label
					font-size 18px
				& input
					height 60px
					border-radius 10px
		&__description
			display flex
			flex-direction column
			justify-content center
			align-items center
		&__title
			margin 30px 0
		&__number
			display flex
			justify-content center
			align-items center
			width 70px
			height 70px
			border-radius 35px
			font-size 24px
			line-height 1
			background-color $primary-yellow
.step-type__wrapper, .step-params__wrapper, .step-activity__wrapper
	display none
