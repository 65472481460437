.page-components
  background-color: rgba(0,0,0,.05)

.components
  margin-left: -20px
  margin-right: -20px

  & h4
    color: white
    text-align: left
    text-transform: uppercase
    font-weight: 300
    letter-spacing: .05em
    text-align: right
    text-shadow: 1px 1px 1px black
    background-color: rgba(0,199,69,.85)
    box-shadow: 3px 3px 3px 0 rgba(0,0,0,.55)
    border-radius: 3px
    padding: 5px
    margin: 30px 0

    &:first-child
      margin-top: 0

  & button + button
    margin-top: 10px

  &__row
    display: flex
    margin: 20px 0

  &__cell
    flex-grow: 1
    flex-shrink: 0
    padding: 20px
    background-color: white
    box-shadow: 0 5px 10px rgba(0,0,0,.55)
    border-radius: 10px
    margin: 10px 20px

  &__cell--2
    width: calc(100%/2 - 40px)

  &__cell--3
    width: calc(100%/3 - 40px)

  &__column-items
    display: flex
    flex-direction: column
    align-items: flex-start
    justify-content: flex-start

    & > *
      margin-bottom: 5px

  &__row-items
    display: flex
    flex-direction: row
    align-items: center
    justify-content: flex-start

  &__payment-icons-list,
  &__crypto-icons-list
    width: 100%
    display: flex
    flex-wrap: wrap
    justify-content: space-around

    & img
      object-fit contain

  &__payment-icons-list img
    height 50px
    width 80px
    margin: 5px 10px

  &__crypto-icons-list img
    height 40px
    width 40px
    margin: 5px

  &__games-icons-list
    display: flex
    flex-wrap: wrap
    justify-content: space-around

    & i
      font-size: 50px
      margin: 10px
