.section
	width 100%
	padding 100px 0
	position relative
	&__header
		position relative
		display flex
		align-items center
		width 100%
		color #000000
		text-align center
	&__title
		font-size 40px
		margin-bottom 10px
	&__subtitle, &__subtitle--link
		display block
		font-size 20px
		font-weight 500
		color #818789
		margin-bottom 20px
		text-align center
		&--link
			color #000000
			text-decoration underline
			&:hover
				text-decoration none
	& .swiper-pagination
		bottom auto
	&--step-by-step, &--faq, &--calculator
		background #ffffff
	&--reviews
		text-align center
		background-color #ffffff
		background-image url(../img/backgrounds/bg_reviews_section.svg)
		background-position: 65px 110px
		background-repeat: no-repeat
		background-size: 80%
	&--test-order
		padding 120px 0
		text-align center
		background-color $primary-yellow
		background-image url(../img/backgrounds/bg_test-order_section.svg)
		background-position: bottom
		background-repeat: no-repeat
		background-size: 100%
		& .section__subtitle
			color #000000
	&--blog
		text-align center
		background-image url(../img/backgrounds/bg_blog_section.svg)
		background-position: bottom
		background-repeat: no-repeat
		background-size: 100%
	&--subscribe
		background-image url(../img/backgrounds/bg-subscribe.png), linear-gradient(90deg, #4598AD 0%, #4158B0 100%)
		background-position: bottom
		background-repeat: no-repeat
		background-size: cover
		padding 0
		& .container
			position relative
			padding 40px 25px
		&__content
			max-width 700px
		&__description
			font-size 44px
			font-weight 600
			color #ffffff
			& span
				color $primary-yellow
		&__img
			position absolute
			right 100px
			max-width 460px
			bottom 0

