.certificate
	&-order
		&-wrapper
			width 640px
			max-width 100%
			background: url(../img/backgrounds/bg-certeficate-order.svg) center no-repeat
			background-color: #fff;
			border-radius: 15px;
			overflow: hidden;
		&-img
			display flex
			justify-content center
			align-items center
			width 70px
			height 70px
			margin 40px auto
			background #ffffff
			border-radius 35px
			box-shadow: 0 8px 10px rgba(0, 0, 0, 0.1)
	&-wrapper
		width 100%
		background: url(../img/backgrounds/bg-certeficate.png) center no-repeat
	&-success

		&__title
			position relative
			display flex
			justify-content center
			align-items center
			width 220px
			height 55px
			background #FFD600
			border-radius 15px
			line-height 1
			&:before
				content ''
				position absolute
				left 25px
				bottom -12px
				display block
				width 15px
				height 15px
				background: url(../img/elements/title-buble.svg) center no-repeat
		&-wrapper
			display flex
			flex-direction column
			align-items center
			justify-content center
			width 640px
			max-width 100%
			text-align center
			height 390px
			background: url(../img/backgrounds/bg-certeficate-success.svg) center no-repeat
			& p
				margin-bottom 0

	&__header
		display flex
		justify-content space-between
		align-items center
		margin-bottom 20px
		padding 40px
		color #ffffff
		background-image url(../img/backgrounds/bg-certeficate.png), linear-gradient(89.84deg, #E13E34 0.12%, #FF7144 48.95%, #EE5540 79.08%, #E13E34 99.86%)
		background-size cover
		background-position top center
		&-description
			display flex
			flex-direction column
			max-width 280px
			font-size 20px
			& h3
				font-size 30px
				margin-bottom 0
	&-img
		max-width 300px
.form--certificate
	display flex
	flex-direction column
	align-items center
	max-width: 280px
	margin-left: auto
	margin-right: auto
	margin-bottom 40px
	& .form__input
		margin-bottom: 20px
	& .button--t6
		color #000
	&-description
		padding 0 20px
		font-size 16px
		font-weight 500
		text-align center
.certificate__page
	background-image: url(../img/backgrounds/bg-mobile-wrapper.svg);
	background-size: cover;
	background-position: center;
	width: 100%
	min-height: 100vh
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 80px 6px;
	background-color: #ffd600;