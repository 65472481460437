/***
*
* Стилизация input type="radio" + списки элементов в форме
*
***/
.radio
	&__item
		display: flex
		align-items: center

		input[type="radio"]
			display: none
			visibility: hidden

			&:checked + label:before
				border-color $primary-gray

			&:checked + label:after
				content ''
				width: 18px
				height: 18px
				border-radius: 50%
				background-color: $primary-gray
				position: absolute
				top: 50%
				left: 3px
				transform translateY(-50%)

		input[type="radio"] + label
			min-height: 24px
			line-height: 24px
			padding-left: 35px
			position: relative
			cursor pointer

			&:before
				content: ''
				width 24px
				height: 24px
				border: 2px solid $primary-gray
				border-radius: 50%
				position: absolute
				left: 0
				top: 50%
				transform translateY(-50%)

	& a
		text-decoration: underline

		&:hover
			color: $primary-red
			text-decoration: none

	// ----- group of radios -----
	&__list
		display: flex
		flex-direction: row
		justify-content: center
		align-items: center
		flex-wrap: wrap

	&__list &__item
		width: auto
		margin: 5px

	&__list--vertical input[type="radio"] + &__item-label
		width: auto
		padding: 30px 10px 0

		&:before
			left: 50%
			top: 0
			transform: translateX(-50%)

	&__list--vertical input[type="radio"]:checked + &__item-label:after
		top: 3px
		left: 50%
		transform translateX(-50%)

	&-img
		&__wrapper
			display flex
			justify-content center
			align-items center
		&__title
			font-size: 18px
			line-height: 22px
			margin-bottom 12px
			text-align center
		&__item
			margin 0 12px
			& .label__icon
				position absolute
				top 8px
				right 8px
			& .marker
				position: relative
				margin-left: 5px
				color #88B337
				&:hover
					& .tooltiptext
						opacity 1
						visibility visible
			& .tooltiptext
				position: absolute
				z-index: 1
				bottom: 125%
				left: 50%
				visibility: hidden
				width: auto
				color: #ffffff
				background: #414141;
				border-radius: 16px
				padding: 5px 10px
				text-align left
				font-size: 14px;
				line-height: 150%;
				transform: translateX(-50%)
				opacity: 0
				transition: opacity .3s
				white-space: nowrap
			& .radio-img__input
				display none
				&:checked + label
					border-color #FAD649
					& img
						opacity 1
			& label
				position relative
				display flex
				justify-content center
				align-items center
				width 102px
				height 102px
				background #F6F6F6
				border-radius 8px
				cursor pointer
				border: 2px solid transparent
				& img
					opacity .5
		&__value
			display flex
			justify-content center
			align-items center
			text-align center
			line-height 1
