// -----------------------------------------------
// Component - Button
// -----------------------------------------------
.button
	position relative
	display inline-flex
	align-items center
	justify-content center
	width 100%
	height 60px
	border-radius 30px
	box-sizing: border-box
	text-align: center
	font-size 17px
	font-weight bold
	line-height 1
	text-transform uppercase
	color #000000
	user-select: none
	padding: 0 15px
	white-space: nowrap
	overflow: hidden
	text-overflow: ellipsis
	text-decoration none
	transition: all 0.5s
	cursor pointer
	&:hover
		transition: all 0.5s
	&.animation
		&:before
			content: ''
			display: inline-block
			transition: background .15s ease-in-out
			background: linear-gradient(90deg,hsla(0,0%,100%,0),hsla(0,0%,100%,.03) 1%,hsla(0,0%,100%,.91) 30%,hsla(0,0%,100%,.94) 50%,hsla(0,0%,100%,.94) 70%,hsla(0,0%,100%,.91) 71%,hsla(0,0%,100%,.03));
			width: 35%
			height: 100%
			transform: skew(-15deg,0deg)
			animation: move 2s
			animation-iteration-count: infinite
			animation-delay: 1s
			position: absolute
			opacity: 0
			top: 0
			left: 0
			pointer-events: none
			overflow hidden
	&--s1
		width 100%
		max-width 250px
	&--s2
		width 100%
		max-width: 220px
	&--s3
		width 100%
		max-width: 180px
	&--s4
		width 100%
		max-width 120px
	&--s5
		width 100%
		max-width 250px
		height 50px
	&--t1
		background-color $primary-yellow
		&:hover
			background-color #ffffff
	&--t2
		color #ffffff
		background-color #df6060
	&--t3
		background-color #ffffff
		&:hover
			background: #df6060
	&--t4
		background-color #ccc
		&:hover
			background: #df6060
	&--t5
		background-color #ffffff
		border 2px solid $primary-yellow
		&:hover
			background: $primary-yellow
	&--t6
		background-color $primary-yellow
		color #ffffff
		&:hover
			background: #df6060
	&--t7
		background-color $primary-yellow
		color #ffffff

	&--login
		display none
		margin-right 25px
	&.read-more
		background #ffd600
		color #282224
		border-radius 10px
		display flex
		justify-content center
		align-items center
		width 50px
		height 30px
		line-height 1
		font-size 24px
		padding-bottom 8px
	&--cookies
		width 166px
		height 30px
		background: #FFD600
		border-radius 15px
		font-size 12px
		text-align center
		font-weight 500
		line-height 33px
		text-transform uppercase
		flex-shrink 0
		transition: all .5s;
		&:hover
			background #ffffff
		&__list
			width 166px
			height 30px
			background: transparent
			border-radius 15px
			font-size 12px
			text-align center
			font-weight 500
			color #FFD600
			line-height 33px
			text-transform uppercase
			flex-shrink 0
			border: 1px solid #FFD600
			margin-right 16px
			transition: all .5s;
			&:hover
				background #FFD600
				color #000000


.button-calc
	&--s1, &--s2
		display flex
		justify-content center
		align-items center
		height 60px
		border 3px solid #eee
		border-radius 10px
		margin 5px 10px
		transition all 0.3s
		&:hover, &.active
			border-color $primary-yellow
			transition all 0.3s
	&--s1
		width 360px
	&--s2
		width 250px
