// -----------------------------------------------
// Component - Social icons for bind in user profile
// -----------------------------------------------

.socials-circle
	display flex

	&__item
		margin 5px

		&-link
			display flex
			align-items center
			justify-content center
			width 40px
			height 40px
			background $secondary-gray
			border-radius 100%
			margin-bottom 10px
			transition: $t-box-shadow

			&:hover
				box-shadow: 0 0 5px 0 rgba(0,0,0,.85)

			i
				text-shadow none
				line-height: 1

			.icon-vk-profile
				font-size: 14px

			.icon-twitter-profile,
			.icon-instagram-profile
				font-size: 18px

			.icon-facebook-profile,
			.icon-google-profile,
			.icon-ok-profile
				font-size: 20px

			&.active .icon-vk-profile
				color #597da3

			&.active .icon-facebook-profile
				color #3b5998

			&.active .icon-twitter-profile
				color #00aced

			&.active .icon-google-profile
				color #d34836

			&.active .icon-ok-profile
				color #ed812b

			&.active .icon-instagram-profile
				color #CB00B7
