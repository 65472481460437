// -----------------------------------------------------------------------------
// Component - Form ------------------------------------------------------------
// -----------------------------------------------------------------------------
.form
	display: flex
	flex-wrap: wrap
	justify-content: space-around

	&__title
		text-transform unset
		text-align center
		margin-bottom 15px
	&__description
		text-align center
	&--subscribe
		justify-content unset
		margin-top 20px
		& .button
			margin 0 10px
			max-width 140px
		& .form__input
			width 300px
			& input
				height 60px
				background #225369
				border-color #225369
				color #fff
				&:focus
					border-color #225369
	&--order-test-menu
		display grid
		grid-template-columns repeat(2,1fr)
		grid-gap 30px
		width 100%
		max-width 580px
		margin-left 60px
	&--order
		display flex
		flex-direction column
		justify-content flex-start
		align-items center
		min-height 440px
		&-step
			display none
			width 100%
			opacity 0
			visibility hidden
			transition all 0.3s ease 0s

			&.current
				display block
				visibility visible
				opacity 1
				transition all 0.3s ease 0s
		& .form__input
			margin-bottom 30px
			max-width 320px
			margin-left auto
			margin-right auto
	&__toggler
		display flex
		justify-content center
		& label
			display flex
			align-items center
	&-navigation
		width 100%
		text-align center
	&-previous
		position absolute
		top 10px
		left 10px
		display flex
		justify-content center
		align-items center
		width 50px
		height 50px
		border-radius 25px
		cursor pointer
		&:hover
			box-shadow 0 0 8px #b0b0b0

	/* ----- Сообщение / Ошибка у элемента формы ------------------------------ */
	.parsley-errors-list
		position absolute
		text-align center
		width 100%
		margin-top 5px

	/* ----- Сообщение / Успех у элемента формы ------------------------------- */


/* -------------------------------------------------------------------------- */
/* ----- Иконка для смены видимости пароля ---------------------------------- */
/* -------------------------------------------------------------------------- */
.pass-switch
	display flex
	align-items center
	justify-content center
	width 40px
	height 40px
	font-size 15px
	color $primary-gray
	position absolute
	top 0
	right 0
	z-index 1
	transition: $t-color
	cursor pointer

	&.icon-hide
		margin-top -1px
		font-size 18px

	&:hover
		color: black

/* -------------------------------------------------------------------------- */
/* ----- Datepicker stuff --------------------------------------------------- */
/* -------------------------------------------------------------------------- */
.form__input--datepicker
	position: relative

	&:after
		content ""
		width: 16px
		height: 16px
		background-image: url("../../img/elements/datepicker-icon.svg")
		background-position: center
		background-size: contain
		background-repeat: no-repeat
		position: absolute
		top: 12px
		right: 10px
		pointer-events: none

	& input[type="text"]
		padding-right: 35px
		cursor: pointer

	&.disabled:before
		opacity: .35

	&.disabled input[type="text"]
		cursor: default
.order__business
	& .form__input
		display flex
		justify-content flex-end
		align-items center
		max-width 100%
		& label
			margin-bottom 0
			margin-right 10px
		& input[type='text'], input[type='email'], input[type='tel']
			max-width 320px
	& .ico-request
		display flex
		align-items center
		justify-content space-around
		& .form__input
			width 230px
			margin 0 10px
		& .button
			width 230px
			height 50px
			font-size 14px
			font-weight 500
			margin 0 10px
			text-transform unset
			color #000000

.form-divider--text
	display flex
	justify-content center
	align-items center
	margin 25px 0
	&:after
		content ''
		display block
		width 15px
		height 15px
		margin-left 10px
		background-image: url("../img/elements/arrow-down.svg")
		background-position: center
		background-size: contain
		background-repeat: no-repeat
