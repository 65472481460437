@media all and (max-width: 1024px)

	.section--test-order
		padding 50px 0
		background-image url(../img/backgrounds/bg_mobile-test-order_section.svg)
		background-position: center
		background-repeat: no-repeat
		background-size: cover
	.section--reviews
		background-image url(../img/backgrounds/bg_mobile-reviews_section.svg)
		background-position top
		background-size cover
	.calculator-calories__result
		background-size cover

	// ---------------------------------------------------------------------------
	// ---- components page styles -----------------------------------------------
	// ---------------------------------------------------------------------------
	.components
		margin-left: 0
		margin-right: 0

		&__row
			flex-wrap: wrap
			margin: 5px 0

		&__cell--2,
		&__cell--3
			width: 100%
			max-width: 100%
			padding: 10px
			margin: 5px 0
