.special-radio
	// ----- list stuff ----------------------------------------------------------
	&__list
		padding-top: 30px
		padding-bottom: 10px

		&-heading
			text-align: center
			font-weight: 400
			margin-bottom: 10px

	// ----- item stuff ----------------------------------------------------------
	background-color: $inputs-bg-color
	box-shadow: $primary-box-shadow
	border: 1px solid $primary-gray
	border-radius: 10px
	margin-bottom: 10px

	&__hidden-input
		display: none
		visibility: hidden

	&__label
		display: flex
		align-items: center
		min-height: 70px
		font-weight: 700
		font-size: 16px
		line-height: 18px
		color: black
		text-transform: uppercase
		text-align: left
		padding: 20px 20px 20px 60px
		transition: $t-color
		position: relative
		cursor: pointer

		&:before,
		&:after
			content: ""
			position: absolute
		&:before
			width: 26px
			height: 26px
			border-radius: 50%
			border: 3px solid $primary-red
			top: 50%
			left: 15px
			transform: translateY(-50%)
		&:after
			width: 14px
			height: 12px
			background-image: url("../img/elements/circle-check.svg")
			background-position: center
			background-size: contain
			background-repeat: no-repeat
			left: 21px
			top: 50%
			transform: translateY(-50%)
			transition: $t-opacity
			opacity: 0

	&__hidden-input:checked + &__label
		color: $primary-red

	&__label:hover
		color: $primary-red

	&__hidden-input:checked + &__label:after
		opacity: 1

	&__content
		display: none
		text-align: left
		padding: 0 50px 18px 60px

	&__hidden-input:checked ~ &__content
		display: block
		margin-top: -8px

	&__row
		display: flex
		font-size: 14px
		line-height: 18px
		color: black
		margin-bottom: 5px

		&--small
			font-size: 12px
			line-height: 14px
			padding-top: 5px
			padding-bottom: 5px

		& .bold-text
			font-weight: 700
			margin-left: 5px

	&__link
		color: $primary-red
		font-weight: 700
		cursor: pointer

		&:hover
			text-decoration: underline
