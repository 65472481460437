// -----------------------------------------------
// Module - Footer
// -----------------------------------------------

.footer
	background-color $secondary-gray
	background-image url('../img/backgrounds/bg_footer.svg')
	background-size: auto 100%
	background-position center
	color #ffffff
	&__layout
		display: flex
		flex-direction: column
		align-items: center
		max-width 1160px
		width 100%
		padding: 60px 0
		margin 0 auto
		font-weight 500
	&__block
		display: flex
		flex-direction: column
		flex-wrap: wrap
		align-items: flex-start
		&-wrapper
			display flex
			justify-content space-between
			width 100%
			@supports (display: grid)
				display: grid
				grid-template-columns: repeat(4, 1fr)
				grid-gap: 20px

		&-title
			min-height 30px
			font-size 18px
			font-weight: bold
			margin-bottom 10px
	&__logo
		height 30px
		margin-bottom 10px
	&__menu
		&-list
			display: flex
			flex-direction: column
			flex-wrap: wrap
			justify-content: space-around

		&-item
			&:not(:last-of-type)
				margin-bottom 15px

			&-link
				text-decoration underline

				&:hover
					text-decoration none
	&__text
		margin-bottom 20px
		line-height 1.5
	&__tel
		margin-bottom 20px
		color #ffd601
		font-weight bold
	&__email
		margin-bottom 20px
	&__payments
		display flex
		align-items center
		width 275px
		& li
			max-width 45px
			&:not(:last-child)
				margin-right 15px
	&__copyright
		display flex
		justify-content space-between
		align-items center
		width 100%
		padding: 15px 0
		color rgba(255, 255, 255, 0.6)
		& p
			margin-bottom: 0

		& a
			text-decoration: underline
			cursor: pointer

			&:hover
				text-decoration: none
