.order
	&-wrapper
		padding 40px
		user-select none
		max-width 670px
		min-height 540px
	&__subtitle
		margin-bottom 0
		text-align center
		text-transform uppercase
	&-steps
		display flex
		justify-content space-between
		align-items flex-start
		margin 30px 0
	&-step
		display flex
		flex-direction column
		justify-content center
		align-items center
		min-width 40px
		margin 0 20px
		font-size 12px
		text-align center
		&__number
			position relative
			display flex
			justify-content center
			align-items center
			width 30px
			height 30px
			font-weight 700
			margin-bottom 5px
			z-index 1
			font-size 15px
			&:before
				content ''
				background #f5f6f6
				height 15px
				left 12px
				position absolute
				top 10px
				width 15px
				z-index -1
				border-radius 50%

			&.current:before
				background #ffd600
			&.done
				background url(../img/elements/done.svg) no-repeat center
				text-indent -9999px
				background-color #ffd600
				border-radius 15px
				&:before
					content none

.last-step
	position absolute
	text-align center
	width 100%
	padding 40px
	background #fff
	top 0
	bottom 0
	height 100%
	left 0
	right 0
	z-index 1
	border-radius 10px
	overflow hidden
	&:before
		content ''
		position absolute
		bottom 0
		left 0
		display block
		width 100%
		height 100%
		background url(../img/backgrounds/bg-order.png) no-repeat
		background-size 100%
		background-position bottom,center
	& .order-steps
		padding-bottom 70px
.last-step-test-order
	display flex
	flex-direction column
	align-items center
	justify-content center
	max-width 100%
	text-align center
	width 480px
	height 400px
	background url(../img/backgrounds/bg-order.png) no-repeat
	background-size 100%
	background-position bottom,center

