/***
*
* Блок пагинации
*
***/

.pager
	padding 25px 0 50px
	margin 0 auto

	&__list
		display flex
		justify-content center

	&__item
		color black
		line-height 28px
		margin 0 5px

		&-link
			display flex
			justify-content center
			align-items center
			width 28px
			height 28px
			font-size 13px
			color black
			line-height 1
			border-radius 2px
			border 1px solid $secondary-gray
			box-sizing border-box
			transition $t-color, $t-border-color

			&:hover
				color $primary-red
				border-color $primary-red

		&--active &-link
			color black
			background-color $secondary-gray
			border none
			cursor text

			&:hover
				color black

		&--load-more .button
			margin 0 auto

		&--prev &-link,
		&--next &-link
			border-color transparent
			position relative

			/* arrows */
			&:before
				content ''
				width 15px
				height 15px
				border-left 2px solid $secondary-gray
				border-bottom 2px solid $secondary-gray
				position absolute
				top 50%
				left 50%
				transition $t-border-color

			&:hover
				border-color transparent

				&:before
					border-color $primary-red

		&--prev &-link:before
			transform translate(-50%, -50%) rotate(45deg)

		&--next &-link:before
			transform translate(-50%, -50%) rotate(225deg)
