.tariff
	&__order
		display flex
		flex-direction column
		justify-content space-between
		align-items center
		text-align center
		padding 20px 15px
		border-radius 15px
		height 420px
		max-width 280px
		width 100%
		position relative
		background #ffffff
		box-shadow 0 1px 3px rgba(39,38,33,.1)
	&__name
		text-transform uppercase
		& h3
			margin-bottom 0
			line-height 1
	&__price
		display flex
		justify-content space-around
		& h3
			margin 0 5px

		&--full
			color #df6060
			text-decoration line-through
		&--day
			color #818789
	&__delivery
		& h6
			text-transform uppercase
			font-weight 400
		& span
			display block
			margin-top 10px
			color #818789
			font-weight 500