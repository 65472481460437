// -----------------------------------------------
// Component - Selectric
// -----------------------------------------------

.selectric-wrapper
	position: relative
	width: 100%
	cursor: pointer
.selectric-responsive
	width: 100%
.selectric
	position: relative
	overflow: hidden
	text-align: left
	background rgba(196,196,196,0.5)
	box-shadow $primary-box-shadow
	border 1px solid $primary-gray
	border-radius 10px
.selectric .label
	display: block
	overflow: hidden
	text-overflow: ellipsis
	white-space: nowrap
	user-select: none
	padding-left 10px
	line-height 38px
	font-weight 700

.selectric .button
	position: absolute
	top: 0
	right: 0
	display: flex
	align-items center
	justify-content center
	color: transparent
	text-align: center
	padding-left 10px
	padding-right 10px
	text-indent -999px
	height 40px
	width 40px
	&:before
		content ''
		width 0
		height 0
		border-top 9px solid #5e5e5e
		border-left 6px solid transparent
		border-right 6px solid transparent
.selectric-open .button
	&:before
		top: 12px
		transform: rotate(180deg)

.selectric-open .selectric .button:after
	top: -10px
	border-color: transparent transparent #707070 transparent
.selectric-open
	z-index: 9999
	box-shadow $primary-box-shadow
	border-radius 10px 10px 0 0
.selectric-open.selectric-above
	border-radius 0 0 10px 10px
	& .selectric
		border-radius 0 0 10px 10px
		border-top 1px solid white
		border-bottom 1px solid $primary-gray
	& .selectric-items
		border-radius 10px 10px 0 0
		border-bottom none
		border-top 1px solid $primary-gray

.selectric-open .selectric
	background white
	border-radius 10px 10px 0 0
	border-bottom 1px solid white
	box-shadow none
.selectric-open .selectric-items
	display: block
	box-shadow $primary-box-shadow
	border 1px solid $primary-gray
	border-top none
	border-radius 0 0 10px 10px
	overflow hidden
	background white
.selectric-disabled
	opacity: 0.5
	cursor: default
	user-select: none
.selectric-hide-select
	position: relative
	overflow: hidden
	width: 0
	height: 0
.selectric-hide-select select
	position: absolute
	left: -100%
.selectric-hide-select.selectric-is-native
	position: absolute
	z-index: 10
	width: 100%
	height: 100%
.selectric-hide-select.selectric-is-native select
	position: absolute
	top: 0
	right: 0
	left: 0
	z-index: 1
	box-sizing: border-box
	width: 100%
	height: 100%
	border: none
	opacity: 0
.selectric-input
	position: absolute !important
	top: 0 !important
	left: 0 !important
	overflow: hidden !important
	clip: rect(0, 0, 0, 0) !important
	margin: 0 !important
	padding: 0 !important
	width: 1px !important
	height: 1px !important
	outline: none !important
	border: none !important
	background: none !important
.selectric-temp-show
	position: absolute !important
	display: block !important
	visibility: hidden !important
.selectric-items
	position: absolute
	top: 100%
	left: 0
	z-index: -1
	display: none
	text-align: left
.selectric-items .selectric-scroll
	overflow: auto
	height: 100%
	border-radius 0 0 10px 10px
.selectric-above .selectric-items
	top: auto
	bottom: 100%
.selectric-items ul, .selectric-items li
	margin: 0
	padding: 0
	min-height: 20px
	list-style: none
	line-height: 20px
.selectric-items li
	display: block
	padding: 0 10px
	line-height: 30px
	cursor: pointer
.selectric-items li.selected
	display none
.selectric-items li:hover,
.selectric-items li.highlighted
	color $primary-red
	background rgba(0,0,0,0.05)
.selectric-items .disabled
	background: none !important
	color: #666 !important
	opacity: 0.5
	cursor: default !important
	user-select: none
.selectric-items .selectric-group .selectric-group-label
	padding-left: 10px
	background: none
	color: #444
	font-weight: bold
	cursor: default
	user-select: none
.selectric-items .selectric-group.disabled li
	opacity: 1
.selectric-items .selectric-group li
	padding-left: 25px

.selectric
	&--void
		cursor initial

		.selectric
			background none

	&--void&-open
		box-shadow none

	&--void &-items
		display none

	&--void .button
		display none

	&--void .label
		cursor text
		
	&--placeholder
		.label
			color white
		
		.button
			&::before
				border-top-color white

	&-open
		.label
			color black