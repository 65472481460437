/***
*
* Форма регистрации
*
***/
.form--registration
	display flex
	align-items center
	max-width 320px
	margin 0 auto

	& .registration__tabs
		& ul.registration__tabs--top
			display flex
			& li
				width 50%
				border-bottom 1px solid white
				padding 0 0 10px 0
				cursor pointer
				&.active
					cursor default
					color #5ABA10
					border-bottom 1px solid #5ABA10
		&--content
			display none
			margin 15px 0 0 0
			&.active
				display flex
		& .intl-tel-input
			background white
