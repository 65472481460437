.step-by-step
	display flex
	justify-content space-between
	text-align center
	&__img
		display flex
		justify-content center
		align-items center
		width 150px
		height 150px
		& img
			object-fit contain
	& li
		display flex
		flex-direction column
		justify-content center
		align-items center
		width 220px
		animation-fill-mode: both
		animation-name fadeInRight
		animation-duration 1s
		&:nth-child(2)
			animation-duration 2s
			animation-delay 1s
		&:nth-child(3)
			animation-duration 3s
			animation-delay 2s
		&:nth-child(4)
			animation-duration 4s
			animation-delay 3s
