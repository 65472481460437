.mobile
	&-bar
		display none
		&__layout
			position relative
			display:flex
			height 70px
			@supports (display: grid)
				display grid
				grid-template-columns 75px auto 75px
				align-items: center
			& > div
				display flex
				align-items center
		&--left
			justify-content flex-start
			& > *
				margin-right 20px
		&--center
			justify-content center
		&--right
			justify-content flex-end
			& > *
				margin-left 20px
		& .icon-menu
			font-size 22px
			&.active
				&:before
					content '\E001'
					width 36px
					height 22px
					display flex
					justify-content center
					align-items center

