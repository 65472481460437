input[type='text'],
input[type='password'],
input[type='email'],
input[type='tel'],
input[type='search'],
textarea
	width: 100%
	height: 50px
	text-align center
	font-size 16px
	line-height: 30px
	background-color #f6f6f6
	border 1px solid #f6f6f6
	border-radius 30px
	color #777
	padding: 0 10px
	box-sizing border-box
	transition: $t-border-color

	&::placeholder
		color: #cccccc
		opacity: 1
		transition: $t-opacity

	&:focus::placeholder
		opacity: 0

	&:focus
		border-color: #cccccc

textarea
	width: 100%
	height 100px
	font-family: inherit
	font-size: inherit
	padding: 10px
	box-sizing: border-box
	resize: none
	overflow: auto
label
	display block
	text-align center
	margin-bottom 10px

	/***
	* Обнуление автозаполнения инпутов в современных браузерах
	***/
	&:-webkit-autofill
		-webkit-animation-name autofill-fix
		-webkit-animation-fill-mode both
	@-webkit-keyframes autofill-fix
		to
			color #fff
			background #c4c4c4
	/***
	* Обнуление автозаполнения инпутов в edge
	***/
	&[class*="edge-auto"]
		color #ffffff !important
		background-color #c4c4c4 !important

		&:focus
			color #ffffff !important
			background-color #c4c4c4 !important

// -----------------------------------------------------------------------------
// Component - Form Input ------------------------------------------------------
// -----------------------------------------------------------------------------
.form__input
	width: 100%
	position: relative

	/* ----- error ------------------------------------------------------------ */
	&.error
		& .form__error-message
			display block

		& input[type='text'],
		& input[type='password'],
		& input[type='email'],
		& input[type='tel'],
		& textarea
			border-color: $primary-red

	/* ----- success ---------------------------------------------------------- */
	&.success
		& .form__success-message
			display block

		& input[type='text'],
		& input[type='password'],
		& input[type='email'],
		& input[type='tel'],
		& textarea
			border-color: $primary-green

	/* ----- disabled --------------------------------------------------------- */
	& input[type='text']:disabled,
	& input[type='password']:disabled,
	& input[type='email']:disabled,
	& input[type='tel']:disabled,
	& textarea:disabled
		color: $primary-gray
		border-color: $primary-gray
		background-color: rgba(196,196,196,.25)
		cursor: default


	/* ----- forgot link message ---------------------------------------------- */
	& .forgot-link
		font-size: 12px
		line-height: 16px
		text-align: right
		position: absolute
		right: 0
		top: 45px
		transition: $t-color
		cursor: pointer

		&:hover
			color $primary-red

	&--small .forgot-link
		top: 30px
input[type='text'], input[type='password'], input[type='email'], input[type='tel'], textarea
	&.parsley-error
		border 1px solid #9f1319
		box-shadow 0 0 5px red inset
.checkbox.parsley-error input[type="checkbox"] + label:before
	box-shadow inset 0 2px 3px #f00, inset 0 -2px 3px #f00
.parsley-required
	display none
