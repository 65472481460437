.sidebar__menu
	width 100%

	&-item
		height 30px

		&-link
			display flex
			justify-content space-between
			align-items center
			font-weight 700
			margin 15px 0
			transition: $t-color
			cursor: pointer

			&:hover,
			&.active
				color $primary-red
