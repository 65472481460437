@media all and (max-width: 480px)
	.fit-menu-tab

		& h3
			font-size 12px
			white-space nowrap
		&__img
			height 50px
	.weekly-nav__weeks li
		width 150px
	.section--subscribe__description
		font-size 28px
		line-height 1.2
	.form--subscribe
		margin-top 0
		& .button, .form__input input
			max-width 280px
		& .button
			margin-top 10px
			margin-left 0
			margin-right 0

	.step-by-step li
		width 49%
		& span
			min-height 75px
	.step-by-step__img
		width 80px
		height 100px

	// ----- tabs stuff -----
	.tabs__caption--profile
		flex-direction: column
		align-items: center
		justify-content: flex-start
		border-bottom: 0
		margin-bottom: 20px

		& li
			width: auto

		&:after
			bottom: 4px

	.profile-tab__content
		padding-top: 5px
	.mfp-zoom-in.mfp-ready .mfp-content
		width 100%
	.test-menu-order .fit-menu-tab__img
		height 50px
		width 40px
	.test-menu-order__wrapper
		padding 30px 10px
		width 100%
	.form--order-test-menu
		width 260px
	.subscribe-succes-wrapper
		width 280px
		height 400px
		background url(../img/backgrounds/bg-subscribe-success-mobile.png) center no-repeat
	.order-step
		margin 0 10px
	.button-calc--s1
		width 250px
	.calculator-step__inputs .form__input
		width 250px
