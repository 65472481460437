@media all and (max-width: 980px)
	// ----- component: HEADER ---------------------------------------------------
	.header
		& .header-menu, .header-info
			display none
	.mobile-toggler
		display flex
	// ------ Sections
	.step-by-step
		justify-content space-around
		flex-wrap wrap
	.section__title
		font-size 30px
	.section--fit-menu, .section--reviews, .section--instagram, .section--blog
		padding 50px 0
		& .container
			padding 0
		& .slider-section__button, .swiper-pagination
			display none
		& .slider-instagram
			margin-bottom 0
	.section--subscribe__content
		max-width 500px
	.section--subscribe__description
		font-size 34px
	.section--subscribe__img
		width 60%
	.review, .post
		max-width 300px
	.review__description
		min-height 90px
	.slider-instagram .swiper-container
		width 100%
	.instagram__post
		max-width 200px
	// ------ fit menu перестроение в слайдер

	.fit-menu-tabs
		padding 0 10px
	.fit-menu__content
		display block
		& .swiper-container
			margin-bottom 20px

	.fit-menu__list
		display flex
		width 100%
		margin-bottom 20px
		grid-gap unset
	.tariff__order
		margin auto
	.fit-menu .eating
		left 0
		opacity 1
		visibility visible
		&:before
			left 0
			top 0
	.weekly-nav
		flex-direction column
		&__weeks
			margin-top 30px
	// ------ Page layout
	.page__header
		height 250px
	.page-sidebar .container
		grid-template-columns 1fr
		& .review__list, .blog__list
			grid-template-columns repeat(3,1fr)
			grid-gap 20px


	// ------ footer layout
	.footer__block-wrapper
		grid-template-columns repeat(3,1fr)

	// ---- Modals
	.form--order-test-menu
		margin-top 30px
		margin-left unset
	.tab-content__wrapper
		flex-direction column
		justify-content center

