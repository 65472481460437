.weekly-nav
	display flex
	justify-content space-between
	align-items center
	margin-bottom 40px
	&__days-list
		display flex
		align-items center
		text-transform uppercase
		font-weight 700
		font-size 17px
		& li, a
			position relative
			display flex
			align-items center
			line-height 1
			padding 5px 15px
			cursor pointer
			&.active:before
				content ''
				position absolute
				left 50%
				top 50%
				transform translate(-50%, -50%)
				z-index -1
				display block
				background #ffd600
				width 45px
				height 45px
				border-radius 30px
				margin 0 auto
		& .day-mobile
			display none
	&__weeks
		display flex
		align-items center
		& li, a
			display flex
			align-items center
			justify-content center
			height 40px
			width 160px
			border-radius 20px
			line-height 1
			cursor pointer
			&.active
				background #ffd600
