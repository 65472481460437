// -----------------------------------------------
// Component - Tabs
// -----------------------------------------------

.tabs
	display: flex
	flex-direction column
.tab-content
	&__wrapper
		display flex
		justify-content space-between
		align-items center



