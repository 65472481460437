/* ------ global statuses --------------------------------------------------- */
.status
	&__active
		color: $primary-green
	&__canceled
		color: $primary-red
	&__pending
		color: $primary-yellow
	&__inactive
		color: $primary-gray

/* ------ circle statuses --------------------------------------------------- */
.circle-status
	display: inline-flex
	width: 10px
	height: 10px
	flex-shrink: 0
	border-radius: 50%
	margin-right: 10px

	&.active
		background-color: $primary-green

	&.canceled
		background-color: $primary-red

	&.pending
		background-color: $primary-yellow

	&.inactive
		background-color: $primary-gray
		opacity: .55

/* ------ tables / x-games statuses ----------------------------------------- */
.status__online,
.status__offline
	width 100%
	display flex
	align-items center
	font-weight 700
	padding 5px 0

	&:before
		content ''
		display block
		width 10px
		height 10px
		border-radius 50%
		margin-right 5px

.status__online
	color $primary-green
	&:before
		background-color $primary-green

.status__offline
	color $primary-red
	&:before
		background-color $primary-red
