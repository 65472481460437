.registration-fast
	position absolute
	top 50%
	z-index 2
	width 300px
	padding 25px
	transform translate(0, -50%)
	background #B4B4B4
	border-radius 10px

	& .socials__list
		margin-bottom 15px

	& .button
		width 100%
