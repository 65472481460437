// -----------------------------------------------
// Typography
// -----------------------------------------------
html
	font-size: $font-size-default
	font-family: $font-primary
h1, h2, h3, h4, h5, h6
	font-weight 900
h1, h2, h3, h4, h5
	line-height: normal
	user-select none
	margin-bottom 25px
h1
	display: block
	width: 100%
	font-size: 60px
	font-weight: 700

h2
	display: block
	width: 100%
	font-size: 30px
	font-weight: 700

h3
	font-size: 25px
	font-weight: 700

p
	margin-bottom: 20px
	line-height 1.5
li, a
	user-select none
