.search
	display flex
	align-items center
	width 260px
	margin auto
	position relative

	& input
		width 100%
		padding-right 40px

	&-button
		display flex
		justify-content center
		align-items center
		box-sizing border-box
		height 40px
		width 40px
		margin-left 5px
		font-size 24px
		position absolute
		right 0
		transition: $t-color
		cursor: pointer

		&:hover
			color $primary-red

	&-wrapping
		& .search-button
			color white
			@media (hover: hover)
				&:hover
					color $primary-red
		& .mfp-close
			display none

.icon-search
	font-size 18px
	transition: $t-color
	cursor: pointer

	@media (hover: hover)
		&:hover
			color $primary-red

[data-search]
	margin 0 15px
