.order-monthly
	display flex
	justify-content space-around
	align-items center
	margin-top 20px
	min-height 140px
	border-radius 15px
	overflow hidden
	padding-left 150px
	padding-right 50px
	box-shadow 0 1px 3px rgba(39,38,33,.1)
	background-color #ffffff
	background-image url(../img/backgrounds/bg-monthly-order.png)
	width 100%
	& h3, p
		margin-bottom 0
	& h3
		font-size 26px
		& span
			color #df6060
			text-decoration line-through
			margin-right 5px
	& p
		font-size 18px
