.widget-banner
	display: flex
	justify-content: center
	align-items: center
	flex-direction column
	width 100%
	background: #FCFBF9 url(../img/backgrounds/bg-sidebar-banner.svg) no-repeat scroll center
	border-radius: 10px
	padding: 50px 0
	background-size: cover
	box-shadow: 0 3px 3px #dcdcdc
	text-align center
	&__title
		font-size 25px
		font-weight bold
		margin-bottom 20px
	&__text
		margin-bottom 20px
