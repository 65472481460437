.mobile-wrapper
	background-image url(../img/backgrounds/bg-mobile-wrapper.svg)
	background-size cover
	background-position center
	& .header-menu
		flex-direction column
		align-items center
		font-size 20px
		padding 0
		margin-bottom 30px
		&__item
			margin 10px 0
			padding 0

.mobile-toggler
	display none
	& .icon-menu
		font-size 16px
		&.active
			font-size 20px
			&:before
				content '\E005'
				width 36px
				height 22px
				display flex
				justify-content center
				align-items center
.contact-phone
	margin-top 30px
