// ----- PASSWORD DIFFICULT ----------------------------------------------------
.password-hints
	display: none
	width: 250px
	padding-top: 25px
	position: absolute
	top: 30px
	left: 50%
	transform: translateX(-50%)
	z-index: 2
	&__simple
		width: 100%
		max-width: 250px
		padding-top: 20px
	&__inner
		width: 100%
		background-color: white
		border: 1px solid $primary-red
		box-shadow: 0 5px 5px 0 rgba(0,0,0,.55)
		border-radius: 10px
		padding: 12px 12px 8px
		position: relative
		&:before
			content: ""
			width: 35px
			height: 35px
			border-radius: 4px
			background-color: white
			border: 1px solid $primary-red
			position: absolute
			left: 50%
			top: -2px
			transform: rotate(45deg) translateX(-50%)
			z-index: -1
		&:after
			content: ""
			width: 40px
			height: 2px
			background-color: white
			position: absolute
			left: calc(50% + 5px)
			top: -1px
			transform: translateX(-50%)
	&__heading
		display: flex
		align-items: center
		text-align: left
		font-size: 12px
		line-height: 14px
		color: black
		margin-bottom: 18px
		&:before
			content: ""
			width: 30px
			height: 20px
			background-image: url("../../img/elements/pass-heading.svg")
			background-position: center
			background-size: contain
			background-repeat: no-repeat
			margin-right: 10px
	&__row
		display: flex
		align-items: center
		font-size: 12px
		line-height: 15px
		text-align: left
		margin-bottom: 5px
		&:before
			content: ""
			width: 11px
			height: 9px
			background-position: center
			background-size: contain
			background-repeat: no-repeat
			margin-right: 10px

		&.success
			color: $primary-green

			&:before
				width: 11px
				height: 9px
				background-image: url("../../img/elements/pass-success.svg")
				margin-right: 17px
				margin-left: 5px

		&.error
			color:$primary-red

			&:before
				width: 15px
				height: 15px
				background-image: url("../../img/elements/pass-error.svg")
				margin-right: 16px
				margin-left: 3px

.form__input input:focus ~ .password-hints
	display: block
