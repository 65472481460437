.slider-instagram
	position relative
	display flex
	justify-content space-between
	align-items center
	margin 50px 0
	& .swiper-container
		width calc(100% - 160px)
		padding 10px 0
	& .swiper-slide
		overflow hidden
		border-radius 15px
		border: 1px solid #e3e2e2
		box-shadow: 0 0 10px #e3e2e2


	& .instagram__img
		padding-bottom: 170%
		position: relative

		& img
			height: 100%
			width: 100%
			object-fit: cover
			position: absolute
			top: 0
			left: 0

			&.no-photo
				object-fit scale-down
