@media all and (max-width: 768px)
	.cookie-messege
		&__wrapper
			left 0
			right 0
			bottom 0
			transform unset
			max-width 100%
			padding 24px 16px
			border-radius 20px 20px 0 0

	.cookie__buttons
		flex-direction column
		align-items flex-start
	.button--cookies
		margin-top 16px
	.slider-main
		& .swiper-pagination, .slider__button-prev
			display none
		& .slider-navigation
			left auto
			bottom 90px
		& .slider-main__content
			bottom 90px
			top auto
			transform unset
			& h2
				margin-bottom 0
				font-size 30px
				line-height 1
				& p
					line-height 1
					margin-bottom 0
			& .slider-main__text
				margin 5px 0
				font-size 20px
				& p
					margin-bottom 0
			& .button
				width 190px
		& .slider-main__img
			min-height 400px
			& img
				top -70px
				bottom unset
	.page__title
		font-size 30px
	.fit-menu-tab
		& span
			display none








	// ----- FAQ  ----------------------------------------------
	.faq-tabs
		flex-wrap wrap
		font-size 15px
		& li
			width auto
			padding 0 15px
			flex-grow 1
			height 60px
			margin 5px
		& .faq-tab04
			flex-grow 2
	// ----- Page layout
	.page-sidebar .container
		& .review__list, .blog__list
			grid-template-columns repeat(2,1fr)
			grid-gap 20px
			justify-items center

@media all and (max-width: 760px)
	.fit-menu-tab
		padding 10px 5px
		max-height 90px
		max-width 100%
		& h3
			font-size 12px
		& img
			width 50px
			height 50px
	//.fit-menu-tab:not(:last-child)
	//	margin-right 0
	.fit-menu-tab:before
		top 20px
		width 25px
		height 25px
	.fit-menu-tab.active:before, .fit-menu-tab:hover:before
		width 25px
		height 25px
	.without-meat .fit-menu-tab.active:before, .without-meat .fit-menu-tab:hover:before
		top 20px
		width 25px
		height 25px
		background-color: #ffd600;
	.fit-menu-switcher
		font-size 13px
	.without-meat .fit-menu-tab.active:after
		content none
	.test-menu-order .without-meat .fit-menu-tab.active:after
		content ''
		background-image: url(../img/backgrounds/bg_leaves_mobile.svg);

