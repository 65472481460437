/***
*
* Форма восстановления пароля
*
***/
.form--restore
	display flex
	align-items center
	max-width 300px
	margin 0 auto
