// -----------------------------------------------
// Styles - for Pages
// -----------------------------------------------
.page
	padding 70px 0
	/***
	*
	* Главная страница
	*
	***/
	&-main
		padding 0

	/***
	*
	* Шапка страницы Блог.
	*
	***/
	&__header
		display: flex;
		align-items: center;
		justify-content: center;
		height: 480px;
		background-image url(../img/backgrounds/bg_blog_header.jpg)
		background-size: cover;
		color #ffffff

	&--center
		justify-content center

	&__title
		width auto
		font-size: 60px
		font-weight 900
		margin-bottom 15px
		text-align center
	&__description
		font-size 18px
		width 100%
		max-width 820px
		margin-bottom 25px

		&-link
			color: $primary-red

			&:hover
				text-decoration: underline
	/***
	*
	* Страница с уведомлением о результате какого-либо действия
	* (отправка сообщения, успешная регистрация, ошибка)
	*
	***/
	&-notification
		display flex
		align-items center
		text-align center

		&__description
			margin-bottom 30px

		&__img
			margin-bottom 25px
	/***
	*
	* Страница с боковым sidebar-ом
	* (блог, статьи, отзывы и т.д.)
	*
	***/

	&-sidebar
		padding 70px 0
		background #ffffff
		& .container
			display grid
			grid-template-columns 1fr 300px
			grid-gap 90px
