/***
*
* iconfont.styl генерируется динамечески со списком классов для иконочного шрифта
* _iconfont-template.styl - шаблон для генерации файла iconfont.styl
*
***/

@font-face
	font-family: "iconfont";
	src: url('../fonts/iconfont/iconfont.eot');
	src: url('../fonts/iconfont/iconfont.eot?#iefix') format('eot'),
		url('../fonts/iconfont/iconfont.woff2') format('woff2'),
		url('../fonts/iconfont/iconfont.woff') format('woff'),
		url('../fonts/iconfont/iconfont.ttf') format('truetype'),
		url('../fonts/iconfont/iconfont.svg?#iconfont') format('svg');


[class^="icon-"], [class*=" icon-"]
	display flex
	justify-content center
	align-items center
	&::before
		font-family "iconfont"
		speak none
		-webkit-font-smoothing antialiased
		-moz-osx-font-smoothing grayscale
		font-style normal
		font-variant normal
		font-weight normal
		text-decoration none
		text-transform none


.icon-arrow-next::before
	content "\E001"

.icon-arrow-prev::before
	content "\E002"

.icon-author::before
	content "\E003"

.icon-block::before
	content "\E004"

.icon-calendar::before
	content "\E005"

.icon-check::before
	content "\E006"

.icon-chevron::before
	content "\E007"

.icon-close::before
	content "\E008"

.icon-done::before
	content "\E009"

.icon-dot::before
	content "\E00A"

.icon-flour::before
	content "\E00B"

.icon-grid::before
	content "\E00C"

.icon-hide::before
	content "\E00D"

.icon-info::before
	content "\E00E"

.icon-login::before
	content "\E00F"

.icon-menu::before
	content "\E010"

.icon-milk::before
	content "\E011"

.icon-phone::before
	content "\E012"

.icon-refresh::before
	content "\E013"

.icon-search::before
	content "\E014"

.icon-sugar::before
	content "\E015"

.icon-toggler::before
	content "\E016"

.icon-view::before
	content "\E017"

.icon-facebook-profile::before
	content "\E018"

.icon-facebook::before
	content "\E019"

.icon-google-profile::before
	content "\E01A"

.icon-google::before
	content "\E01B"

.icon-instagram-profile::before
	content "\E01C"

.icon-instagram::before
	content "\E01D"

.icon-telegram::before
	content "\E01E"

.icon-twitter-profile::before
	content "\E01F"

.icon-twitter::before
	content "\E020"

.icon-vk-profile::before
	content "\E021"

.icon-vk::before
	content "\E022"

.icon-youtube::before
	content "\E023"
