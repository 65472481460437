// -----------------------------------------------
// Base
// -----------------------------------------------
html
	scroll-behavior smooth
body
	color: black
	background #faf9f4
	&.fixed
		overflow: hidden
		height: 100vh
	&.blur
		& .main
			filter blur(3px)
			transition all .3s ease-out
.helper
	margin-top: 70px
	display: flex
	flex-direction: column
	justify-content: space-between
	min-height: 100vh
	background-color: transparent


a, img
	-webkit-user-drag none
	-khtml-user-drag none
	-moz-user-drag none
	-o-user-drag none
	user-drag none

