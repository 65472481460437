.slider-main
	background $secondary-gray
	& .swiper-pagination-bullet
		width 12px
		height 12px
		background #ffffff
		margin 0 10px
		&-active
			background $primary-yellow

	&__img
		position relative
		min-height 658px
		height calc(100vh - 70px)
		max-height 1080px
		& img
			position: absolute
			bottom: -1px
			width calc(100% + 2px)
	&__content
		position absolute
		top 50%
		left 0
		right 0
		transform translateY(-50%)
		max-width 1160px
		margin auto
		color #ffffff
		& h2
			text-align: left
			font-weight: 700
			font-size: 60px
			line-height: 70px
	&__text
		display block
		margin 40px 0
		font-size 30px
		font-weight 500


.slide
	&--01
		//background-image linear-gradient(270deg, #F19036 2.4%, #CEA423 50.5%, #A4BD0C 97.6%)
		background-image linear-gradient(270deg,#f19036 0%,rgba(225,62,52,.9) 97.6%)
	&--02
		background-image linear-gradient(90deg,#f19036 0%,#a832a3 97.6%)
	&--03
		background-image linear-gradient(270deg,#4c6ec5 0%,#a832a3 97.6%)
	&--04
		//background-image linear-gradient(90deg,#4c6ec5 0%,#E13E34 97.6%)
		background-image linear-gradient(90deg,#4c6ec5 0%,rgba(225,62,52,.9) 97.6%)
	&--05
		background-image linear-gradient(270deg, #A4BD0C 4.2%, #D3AD33 50.44%, #E13E34 97.6%)
		//background-image linear-gradient(90deg,#4c6ec5 0%,rgba(225,62,52,.9) 97.6%);


